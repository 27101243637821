import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

export const InfoBoxSt = {
  Wrap: styled.div`
    position: absolute;
    right: 80px;
    bottom: 30px;
    z-index: 9;
    box-sizing: border-box;
    //max-width: 320px;
    min-width: 360px;

    ul {
      display: flex;
      flex-direction: column;
      gap: 2px;
      li {
        display: flex;
        color: #272727;
        font-weight: 300;
        font-size: 13px;
        word-break: keep-all;
        &:not(:last-child) {
          /* margin-bottom: 16px; */
        }
        > span {
          min-width: 110px;
          color: #656565;
          font-weight: 300;
          font-size: 13px;
        }
      }
    }
  `,
  Head: styled.div`
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #00b5b5;
    border-radius: 2px 2px 0 0;
    font-size: 16px;
    font-weight: 700;
    font-family: 'Pretendard';
    color: #fff;
    padding: 0 24px;
  `,
  Cont: styled.div`
    background: #fff;
    border: 2px solid #00b5b5;
    border-radius: 0 0 2px 2px;
  `,
  Inner: styled.div`
    padding: 20px 24px;
  `,
  Close: styled.button`
    position: relative;
    width: 20px;
    height: 20px;
    &:before,
    &:after {
      position: absolute;
      left: 50%;
      top: 50%;
      content: '';
      width: 24px;
      height: 1px;
      background: #fff;
    }
    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  `,
};

function FarmInfoBox({ property, active, show, close }: { property: any; active: boolean; show: boolean; close: () => void }) {
  // property.pnu ->  시도(2) + 시군구(3) + 읍면동(3) + 리(2) + 필지구분(1) + 본번(4) + 부번(4) = 19자리
  console.log(property);

  return (
    <InfoBoxSt.Wrap style={{ display: active && show ? 'block' : 'none' }}>
      <InfoBoxSt.Head>
        정보
        <InfoBoxSt.Close type={'button'} onClick={close}>
          <span className={'hidden'}>닫기</span>
        </InfoBoxSt.Close>
      </InfoBoxSt.Head>
      <InfoBoxSt.Cont>
        <div className="cScroll" style={{ height: '300px' }}>
          <InfoBoxSt.Inner>
            <ul>
              <li>
                <span>아이디</span> {property?.id || '-'}
              </li>
              <li>
                <span>유아이디</span> {property?.uid || '-'}
              </li>
              <li>
                <span>분류명</span> {property?.clsf_nm || '-'}
              </li>
              {/* <li>
                <span>분류코드</span> {'--' || '-'}
              </li> */}
              <li>
                <span>법정동코드</span> {property?.stdg_cd || '-'}
              </li>
              <li>
                <span>법정동주소</span> {property?.stdg_addr || '-'}
              </li>
              <li>
                <span>대표PNU</span> {property?.pnu || '-'}
              </li>
              <li>
                <span>대표지목</span> {property?.ldcg_cd || '-'}
              </li>
              <li>
                <span>부PNU</span> {property?.sb_pnu || '-'}
              </li>
              <li>
                <span>부지목</span> {property?.sb_ldcg_cd || '-'}
              </li>
              <li>
                <span>면적</span> {property?.area || '-'}
              </li>
              <li>
                <span>지적일치율</span> {property?.cad_con_ra || '-'}
              </li>
              <li>
                <span>판독영상</span> {property?.source_nm || '-'}
              </li>
              {/* <li>
                <span>판독영상코드</span> {'--' || '-'}
              </li> */}
              <li>
                <span>영상촬영일자</span> {property?.flight_ymd || '-'}
              </li>
              <li>
                <span>갱신일자</span> {property?.updt_ymd || '-'}
              </li>
              <li>
                <span>갱신유형</span> {property?.updt_tp_nm || '-'}
              </li>
              {/* <li>
                <span>갱신유형코드</span> {'--' || '-'}
              </li> */}
              <li>
                <span>변경사유</span> {property?.chg_rsn_nm || '-'}
              </li>
              {/* <li>
                <span>변경사유코드</span> {'--' || '-'}
              </li> */}
              <li>
                <span>경지정리여부</span> {property?.fl_armt_yn || '-'}
              </li>
              <li>
                <span>전차_유아이디</span> {property?.o_uid || '-'}
              </li>
              <li>
                <span>전차_분류명</span> {property?.o_clsf_nm || '-'}
              </li>
            </ul>
          </InfoBoxSt.Inner>
        </div>
      </InfoBoxSt.Cont>
    </InfoBoxSt.Wrap>
  );
}

export default FarmInfoBox;
