import React from 'react';
import styled from 'styled-components';

import LoginBg from 'assets/images/login/login-bg.png';
import BannerTitle from 'assets/images/login/login-banner-title.svg';
import BannerLogo from 'assets/images/login/banner-logo.svg';
import LoginLogo from 'assets/images/login/login-logo.svg';
import IcoBuilding from 'assets/images/login/ico-building.svg';
import IcoLocation from 'assets/images/login/ico-location.svg';
import IcoCheck from 'assets/images/login/ico-check.svg';
import Input from 'components/atoms/Input';
import Button from 'components/atoms/Button';
import IcoUser from 'assets/images/login/ico-user.svg';
import IcoPassword from 'assets/images/login/ico-password.svg';
import useOnChange from 'hooks/useOnChange';

const LoginStyle = {
  Wrap: styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
  `,
  BannerWrap: styled.div`
    width: 800px;
    height: 100%;
    position: relative;
    .bgShadow {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 0;
      background: rgba(4, 146, 108, 0.65);
      filter: blur(25px);
    }
    .bgImage {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 9;
      background: url(${LoginBg}) no-repeat center;
      .title {
        width: 722px;
        height: 135px;
        background: url(${BannerTitle}) no-repeat center;
        position: absolute;
        top: 126px;
        right: 0;
      }
      .scriptWrap {
        position: absolute;
        bottom: 88px;
        left: 80px;
        .logo {
          width: 132px;
          height: 24.47px;
          background: url(${BannerLogo}) no-repeat center;
        }
        .script {
          margin-top: 16.45px;
          color: #fff;
          font-size: 20px;
          font-weight: 600;
        }
      }
    }
  `,
  FormWrap: styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    .copyRight {
      margin-top: auto;
      margin-bottom: 48px;
      color: #7d8888;
      font-weight: 500;
    }
  `,
  LoginWrap: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 185px;
    .logo {
      margin-bottom: 56px;
      width: 194.203px;
      height: 36px;
      background: url(${LoginLogo}) no-repeat center;
    }
    .formWrap {
      margin-bottom: 40px;
      display: flex;
      flex-direction: column;
      gap: 6px;
    }
  `,
  SiteWrap: styled.div`
    padding-top: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .icoMain {
      margin-bottom: 24px;
      width: 64px;
      height: 64px;
      background: url(${IcoBuilding}) no-repeat center;
    }
    .info {
      margin-bottom: 30px;
      font-size: 22px;
      font-weight: 500;
    }
    .listBox {
      margin-bottom: 24px;
      width: 360px;
      height: 220px;
      padding: 20px;
      /* padding-right: 7px; */
      box-sizing: border-box;
      border-radius: 8px;
      background-color: #f0f0f0;
      ul {
        height: 100%;
        overflow: auto;
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding-right: 5px;
        &::-webkit-scrollbar {
          width: 8px;
          padding-left: 10px;
        }
        &::-webkit-scrollbar-track {
          background-color: transparent;
        }
        li {
          height: 48px;
          padding: 16px;
          display: flex;
          align-items: center;
          gap: 10px;
          box-sizing: border-box;
          background-color: #fff;
          border-radius: 4px;
          font-size: 16px;
          cursor: pointer;
          &:hover {
            background-color: #d1f1e6;
            /* font-weight: 700; */
          }
          .ico {
            display: inline-block;
            width: 24px;
            height: 24px;
            background: url(${IcoLocation}) no-repeat center;
          }
          &.active {
            position: relative;
            background-color: #d1f1e6;
            font-weight: 700;
            &::after {
              content: '';
              width: 24px;
              height: 24px;
              background: url(${IcoCheck}) no-repeat center;
              position: absolute;
              top: 50%;
              right: 16px;
              transform: translateY(-50%);
            }
          }
        }
      }
    }
  `,
};
function Login() {
  const [form, setForm, onChange] = useOnChange({
    userId: '',
    password: '',
  });
  console.log(form);

  return (
    <LoginStyle.Wrap>
      <LoginStyle.BannerWrap>
        <div className="bgImage">
          <div className="title"></div>
          <div className="scriptWrap">
            <div className="logo"></div>
            <p className="script">드론영상 기반 WEB 품목분류 솔루션</p>
          </div>
        </div>
        <div className="bgShadow"></div>
      </LoginStyle.BannerWrap>
      <LoginStyle.FormWrap>
        {/* 로그인 Form */}
        <LoginStyle.LoginWrap>
          <div className="logo"></div>
          <div className="formWrap">
            <Input width={360} height={56} fontSize={16} type="text" id="userId" name="userId" placeholder="아이디 입력" leftIco={IcoUser} onChange={onChange} />
            <Input width={360} height={56} fontSize={16} type="password" id="password" name="password" placeholder="패스워드 입력" leftIco={IcoPassword} onChange={onChange} />
          </div>
          <Button width={360} height={56} text="로그인" />
        </LoginStyle.LoginWrap>
        {/* 사업장 선택 */}
        <LoginStyle.SiteWrap>
          <div className="icoMain"></div>
          <div className="info">사업장을 선택하세요</div>
          <div className="listBox">
            <ul className="cScroll">
              <li>
                <span className="ico"></span>제주애월농장
              </li>
              <li className="active">
                <span className="ico"></span>제주성한사과과수원
              </li>
              <li>
                <span className="ico"></span>제주망고농장
              </li>
              <li>
                <span className="ico"></span>제주양배추밭
              </li>
              <li>
                <span className="ico"></span>제주애월농장2
              </li>
            </ul>
          </div>
          <Button width={360} height={56} text="사업장 접속" />
        </LoginStyle.SiteWrap>

        <div className="copyRight">Copyright 2024. Movements All rights reserved.</div>
      </LoginStyle.FormWrap>
    </LoginStyle.Wrap>
  );
}

export default Login;
