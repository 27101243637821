import VectorModule from '../Layer/VectorModule';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import OlCore from '../OlCore';
import GeoJSONFormat from 'ol/format/GeoJSON';
import * as loadingstrategy from 'ol/loadingstrategy';

import { click, platformModifierKeyOnly } from 'ol/events/condition';
import { Collection, Feature } from 'ol';
import { getPointUrl } from 'service/point';
import { shapeStyle, withTextStyle } from 'ol/Style/PointStyle';
import { DragBox, Select, Modify, Snap } from 'ol/interaction';

import PointModule from 'ol/Layer/PointModule';
import { getWidth } from 'ol/extent';
import axios from 'axios';

class ObjectPoint extends PointModule {
  private DogeunLayer2: VectorLayer<VectorSource>;

  private DogeunLayer: VectorLayer<VectorSource>;
  private DogeunSource!: VectorSource;

  private TriangleLayer!: VectorLayer<VectorSource>;
  private TriangleSource!: VectorSource;

  private AssistantLayer!: VectorLayer<VectorSource>;
  private AssistantSource!: VectorSource;

  private clickEvent!: Select;

  constructor(core: OlCore) {
    super(core);
    // 지적도근점 레이어
    this.DogeunLayer2 = new VectorLayer<VectorSource>({
      properties: {
        id: 'DogeunLayer2',
      },
      visible: true,
      zIndex: 9,
      // minZoom: 16.5,
      // maxZoom: Infinity,
    });
    // 지적도근점 레이어
    this.DogeunLayer = new VectorLayer<VectorSource>({
      properties: {
        id: 'DogeunLayer',
      },
      visible: false,
      zIndex: 9,
      // minResolution: 0,
      // maxResolution: 0.0001,
      minZoom: 16.5,
      maxZoom: Infinity,
    });

    // 지적삼각점 레이어
    this.TriangleLayer = new VectorLayer<VectorSource>({
      properties: {
        id: 'TriangleLayer',
      },
      visible: false,
      zIndex: 9,
      minZoom: 13.5,
      maxZoom: Infinity,
    });

    // 지적삼각점 레이어
    this.AssistantLayer = new VectorLayer<VectorSource>({
      properties: {
        id: 'AssistantLayer',
      },
      visible: false,
      zIndex: 9,
      minZoom: 13.5,
      maxZoom: Infinity,
    });

    this.setLayers([this.DogeunLayer2, this.DogeunLayer, this.TriangleLayer, this.AssistantLayer]);
  }

  /* ---------------- DRAW ---------------- */

  // 지적도근점 - GeoServer 파일 요청 데이터
  public draw(data: any) {
    const geoData = data.features;

    const features: any = this.createPointFeatures(geoData);

    const source = this.createSource(features);

    this.DogeunLayer2.setSource(source);
    this.DogeunLayer2.setStyle((feature) => shapeStyle(feature, 'circle', '#ffcc00'));
  }

  // 지적도근점 WFS - Dogeun
  public drawDogeun() {
    this.DogeunSource = new VectorSource({
      format: new GeoJSONFormat(),
      // url: function (extent) {
      //   let pointUrl = getPointUrl({ key: 'Dogeun', bbox: extent });
      //   return pointUrl;
      // },
      loader: async (extent, resolution, projection, success: any, failure: any) => {
        let pointUrl = getPointUrl({ key: 'Dogeun', bbox: extent });
        let response = await axios.get(pointUrl);

        //피쳐 배열 생성 후 벡터소스에 추가
        const features = this.DogeunSource?.getFormat()?.readFeatures(response.data) || [];

        if (features.length > 0) {
          // * 중복 피쳐 필터링 * //
          const key = 'src_objectid';
          // 1) 기존 도근점의 src_objectid 배열
          let ids = new Set(this.DogeunSource.getFeatures().map((feature) => feature.getProperties()?.[key]));
          // 2) 새로 요청한 response에서 기존에 없던 항목만 필터링
          const newFeatures: any[] = features.filter((feature) => !ids.has(feature.getProperties()?.[key]));

          this.DogeunSource.addFeatures(newFeatures);
          //  console.log(this.DogeunSource.getFeatures());
        }
      },
      strategy: loadingstrategy.bbox,
    });

    this.DogeunLayer.setSource(this.DogeunSource);
    this.DogeunLayer.setStyle((feature) => withTextStyle(feature));
  }

  // 지적삼각점 WFS - Triangle
  public drawTriangle() {
    this.TriangleSource = new VectorSource({
      format: new GeoJSONFormat(),
      // url: function (extent) {
      //   let pointUrl = getPointUrl({ key: 'Triangle', bbox: extent });
      //   return pointUrl;
      // },
      loader: async (extent, resolution, projection, success: any, failure: any) => {
        let pointUrl = getPointUrl({ key: 'Triangle', bbox: extent });
        let response = await axios.get(pointUrl);

        //피쳐 배열 생성 후 벡터소스에 추가
        const features = this.TriangleSource?.getFormat()?.readFeatures(response.data) || [];

        if (features.length > 0) {
          // * 중복 피쳐 필터링 * //
          const key = 'src_objectid';
          let ids = new Set(this.TriangleSource.getFeatures().map((feature) => feature.getProperties()?.[key]));
          const newFeatures: any[] = features.filter((feature) => !ids.has(feature.getProperties()?.[key]));

          this.TriangleSource.addFeatures(newFeatures);
        }
      },
      strategy: loadingstrategy.bbox,
    });

    this.TriangleLayer.setSource(this.TriangleSource);
    this.TriangleLayer.setStyle((feature) => withTextStyle(feature));
  }

  // 지적삼각보조점 WFS - Assistant
  public drawAssistant() {
    this.AssistantSource = new VectorSource({
      format: new GeoJSONFormat(),
      // url: function (extent) {
      //   return getPointUrl({ key: 'Assistant', bbox: extent });
      // },
      loader: async (extent, resolution, projection, success: any, failure: any) => {
        let pointUrl = getPointUrl({ key: 'Assistant', bbox: extent });
        let response = await axios.get(pointUrl);

        //피쳐 배열 생성 후 벡터소스에 추가
        const features = this.AssistantSource?.getFormat()?.readFeatures(response.data) || [];

        if (features.length > 0) {
          // * 중복 피쳐 필터링 * //
          const key = 'src_objectid';
          let ids = new Set(this.AssistantSource.getFeatures().map((feature) => feature.getProperties()?.[key]));
          const newFeatures: any[] = features.filter((feature) => !ids.has(feature.getProperties()?.[key]));

          this.AssistantSource.addFeatures(newFeatures);
        }
      },
      strategy: loadingstrategy.bbox,
    });

    this.AssistantLayer.setSource(this.AssistantSource);
    this.AssistantLayer.setStyle((feature) => withTextStyle(feature));
  }

  /* ---------------- EVENT ---------------- */

  //포인트 클릭
  public onClickPoint(callback) {
    this.clickEvent = new Select({
      layers: [this.DogeunLayer, this.TriangleLayer, this.AssistantLayer, this.DogeunLayer2],
      condition: click,
      style: (feature) => withTextStyle(feature, true),
    });
    // 클릭 이벤트에대한 콜백 함수 설정
    this.clickEvent.on('select', (e) => {
      //e.target.getFeatures는 Collection으로 반환한다.
      const targetCollection = e.target.getFeatures() as Collection<Feature>;
      if (targetCollection.getLength()) {
        //선택 객체 추출
        this.selectedFeature = targetCollection.getArray()[0];

        //React Component에서 콜백
        callback && callback(this.selectedFeature);

        // 선택 객체 프로퍼티
        //   let properties = this.selectedFeature?.getProperties();
        //   console.log(properties);

        // * (속도저하) shp 파일에서 일치하는 항목 찾기 * //
        //   let shpFeatures = (GeoJsonSeoul as any).features;
        //   let shpFeatures2 = (GeoJsonKyungKi as any).features;

        // shp 파일의 [BASE_POINT]와, API 프로퍼티 중  [lgstr_splmcpt_idntfc_no] 일치 하는 항목 가져오기.
        //   let targetShpFeature = shpFeatures?.find((ele) => {
        //     return ele.properties.BASE_POINT === this.selectedFeature?.getProperties()?.lgstr_splmcpt_idntfc_no;
        //   });
        //   let targetShpFeature2 = shpFeatures2?.find((ele) => {
        //     return ele.properties.BASE_POINT === this.selectedFeature?.getProperties()?.lgstr_splmcpt_idntfc_no;
        //   });

        //   console.log(targetShpFeature); // 세계측지계 key: X_CODE / Y_CODE  api 비교 : x_crdnt
        //   console.log(targetShpFeature2); // 세계측지계 key: X_CODE / Y_CODE
      } else {
        callback && callback({});
      }
    });
    this.core.mapInstance.addInteraction(this.clickEvent);
  }

  public onClickPointClear() {
    this.clickEvent.getFeatures().clear();
  }

  // Drag Box에 포함된 도근점 | 삼각점 | 삼각보조점 찾기
  public dragBoxPoint() {
    let selectedFeatures = this.clickEvent.getFeatures(); // CHECK: 원하는 Select

    // 참고) style 옵션 대신 .ol-dragbox 클래스
    const dragBox = new DragBox({
      className: 'ol-dragbox',
      condition: platformModifierKeyOnly, // Ctrl+drag
    });

    // 드래그 종료시
    dragBox.on('boxend', () => {
      const boxExtent = dragBox.getGeometry().getExtent();
      console.log(boxExtent);

      // 도근점 레이어(Select)와 겹치는 피쳐 구하기
      const worldExtent_0 = this.core.mapInstance.getView().getProjection().getExtent(); // 기존코드) Error....
      const worldExtent = this.core.mapInstance.getView().calculateExtent();

      const worldWidth = getWidth(worldExtent);
      const startWorld = Math.floor((boxExtent[0] - worldExtent[0]) / worldWidth);
      const endWorld = Math.floor((boxExtent[2] - worldExtent[0]) / worldWidth);

      for (let world = startWorld; world <= endWorld; ++world) {
        const left = Math.max(boxExtent[0] - world * worldWidth, worldExtent[0]);
        const right = Math.min(boxExtent[2] - world * worldWidth, worldExtent[2]);
        const extent = [left, boxExtent[1], right, boxExtent[3]];

        // * dragBox extent와 겹치는 Feature 찾기 (CHECK: 원하는 벡터 소스 (ex) 도근점 레이어))
        // 기존 selectedFeatures에 없는 항목만! * //
        function getBoxFeatures(targetSource) {
          return targetSource.getFeaturesInExtent(extent).filter((feature: any) => !selectedFeatures.getArray().includes(feature) && feature.getGeometry().intersectsExtent(extent));
        }

        // 박스에 포함된 feature 배열
        const boxFeatures = [...getBoxFeatures(this.DogeunSource), ...getBoxFeatures(this.TriangleSource), ...getBoxFeatures(this.AssistantSource)];
        console.log('boxFeatures', boxFeatures);

        // * 추가 조건) view가 비스듬히 회전한 경우
        const rotation = this.core.mapInstance.getView().getRotation();
        const oblique = rotation % (Math.PI / 2) !== 0; // 지도 view의 기울기 유무 구하기.
        // 기울기가 있으면
        if (oblique) {
          const anchor = [0, 0];
          const geometry = dragBox.getGeometry().clone(); // dragBox에도 기울기 추가
          geometry.translate(-world * worldWidth, 0);
          geometry.rotate(-rotation, anchor);
          const extent = geometry.getExtent(); // 기울기가 적용된 box의 extent값

          // boxFeatures의 feature에도 기울기 주기, extent 겹치는 feature의 경우
          boxFeatures.forEach(function (feature: any) {
            const geometry = feature.getGeometry().clone();
            geometry.rotate(-rotation, anchor);
            if (geometry.intersectsExtent(extent)) {
              selectedFeatures.push(feature); // selected에 feature 직접 추가.
            }
          });
        } else {
          // 기울기가 없으면
          selectedFeatures.extend(boxFeatures); // selected에  boxFeatures 추가.
        }
      }
    });

    // 드래그 시작시
    dragBox.on('boxstart', function () {
      selectedFeatures.clear(); // selected 피쳐 초기화
    });

    this.core.mapInstance.addInteraction(dragBox);

    //
    selectedFeatures.on(['add', 'remove'], function () {
      const names = selectedFeatures.getArray().map((feature) => {
        return feature.get('ECO_NAME');
      });
      if (names.length > 0) {
        //   infoBox.innerHTML = names.join(', ');
      } else {
        //   infoBox.innerHTML = 'None';
      }
    });
  }
}

export default ObjectPoint;
