import Draw from 'ol/interaction/Draw.js';
import VectorModule from './VectorModule';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Style, Circle, Fill, Stroke } from 'ol/style.js';
import { Feature, Overlay } from 'ol';
import { LineString, Point, Polygon } from 'ol/geom';
import { getArea, getLength } from 'ol/sphere.js';
import { unByKey } from 'ol/Observable.js';
import OlCore from '../OlCore';
import { Snap } from 'ol/interaction';
import ObjectPoint from '../Object/ObjectPoint';

let helpTooltipElement: any;
let helpTooltip: any;
let measureTooltipElement: any;
let measureTooltip: any;
let btnRemove: any;
let featureId: number = 1;
class MeasureModule extends VectorModule {
  private source: VectorSource = new VectorSource();
  private pointSource: VectorSource = new VectorSource();
  private sketch?: Feature | null;
  private continuePolygonMsg = '마우스 오른쪽 버튼을 클릭하여 다각형을 그리세요.';
  private continueLineMsg = '마우스 오른쪽 버튼을 클릭하여 선을 그리세요.';
  private draw?: Draw;
  private drawPoint?: Draw;
  private polyVector: VectorLayer<VectorSource>;
  private pointVector: VectorLayer<VectorSource>;
  private dogeunSnap!: Snap | null;
  private triangleSnap!: Snap | null;
  private assistantSnap!: Snap | null;

  private pointerMoveHandler(e: any) {
    if (e.dragging) {
      return;
    }
    let helpMsg = '마우스 우클릭하여 그리기';

    if (this.sketch) {
      const geom = this.sketch.getGeometry();
      if (geom instanceof Polygon) {
        helpMsg = this.continuePolygonMsg;
      } else if (geom instanceof LineString) {
        helpMsg = this.continueLineMsg;
      }
    }
    if (helpTooltipElement) {
      helpTooltipElement.innerHTML = helpMsg;
      helpTooltip.setPosition(e.coordinate);
      helpTooltipElement.classList.remove('hidden');
    }
  }

  private formatLength(line: LineString) {
    const length = getLength(line, { projection: 'EPSG:5186' });
    let output;
    if (length > 100) {
      output = `
                <div class="rowFlex">
                  <span class="label">총거리</span>
                  <div>
                    <span class="value">${Math.round(length / 10) / 100}</span>
                    <span class="unit">km</span>
                  </div>
                </div>
            `;
    } else {
      output = `
                <div class="rowFlex">
                  <span class="label">총거리</span>
                  <div>
                    <span class="value">${Math.round(length * 100) / 100}</span>
                    <span class="unit">m</span>
                  </div>
                </div>
            `;
    }
    return output;
  }

  private formatArea(polygon: Polygon) {
    const area = getArea(polygon, { projection: 'EPSG:5186' });
    let output;
    if (area > 10000) {
      output = `
                <div class="rowFlex">
                  <span class="label">총면적</span>
                  <div>
                    <span class="value">${Math.round((area / 1000000) * 100) / 100}</span>
                    <span class="unit">km<sup>2</sup></span>
                  </div>
                </div>
            `;
    } else {
      output = `
                <div class="rowFlex">
                  <span class="label">총면적</span>
                  <div>
                    <span class="value">${Math.round(area * 100) / 100}</span>
                    <span class="unit">m<sup>2</sup></span>
                  </div>
                </div>
            `;
    }
    return output;
  }

  private style = (type) =>
    new Style({
      fill: new Fill({
        color: 'rgba(255, 255, 255, 0.2)',
      }),
      stroke: new Stroke({
        color: type === 'Polygon' ? '#385EFA' : '#EB5B14',
        lineDash: [10, 10],
        width: 2,
      }),
      image: new Circle({
        radius: 5,
        stroke: new Stroke({
          color: 'rgba(0, 0, 0, 0.7)',
        }),
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.2)',
        }),
      }),
    });

  private pointVectorStyle = (feature) => {
    const type = feature.getGeometry().getType();
    return [
      new Style({
        fill: new Fill({ color: 'rgba(255,255,255,.2)' }),
        stroke: new Stroke({
          width: 4,
          color: type === 'Polygon' ? 'rgba(112, 135, 231, 0.7)' : 'rgba(254, 128, 67, 0.7)',
        }),
      }),
    ];
  };

  private createHelpTooltip(status: boolean) {
    if (helpTooltipElement) {
      helpTooltipElement.parentNode.removeChild(helpTooltipElement);
    }
    helpTooltipElement = document.createElement('div');
    helpTooltipElement.className = 'ol-tooltip hidden';
    helpTooltip = new Overlay({
      element: helpTooltipElement,
      id: 'measure',
      offset: [15, 0],
      positioning: 'center-left',
    });
    if (status) {
      this.core.mapInstance.addOverlay(helpTooltip);
    } else {
      this.core.mapInstance.removeOverlay(helpTooltip);
    }
  }

  private createMeasureTooltip(status: boolean) {
    if (measureTooltipElement) {
      measureTooltipElement?.parentNode?.removeChild(measureTooltipElement);
    }
    measureTooltipElement = document.createElement('div');

    measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';
    measureTooltip = new Overlay({
      element: measureTooltipElement,
      offset: [0, -15],
      id: 'measure',
      positioning: 'bottom-center',
      stopEvent: false,
      insertFirst: false,
    });
    if (status) {
      this.core.mapInstance.addOverlay(measureTooltip);
    } else {
      this.core.mapInstance.removeOverlay(measureTooltip);
    }
  }
  public addInteraction(status: boolean, type: 'Polygon' | 'LineString') {
    // const type = this.typeSelect.value == 'area' ? 'Polygon' : 'LineString';
    let listener: any;
    const drawStart = (evt: any) => {
      // set sketch
      this.sketch = evt.feature;
      let tooltipCoord = evt.target.coordinate;
      listener = (this.sketch?.getGeometry() as any).on('change', (evt: any) => {
        const geom = evt.target;
        let output;
        if (geom instanceof Polygon) {
          output = this.formatArea(geom);
          tooltipCoord = geom.getInteriorPoint().getCoordinates();
        } else if (geom instanceof LineString) {
          output = this.formatLength(geom);
          tooltipCoord = geom.getLastCoordinate();
        }
        measureTooltipElement.innerHTML = output;
        measureTooltip!.setPosition(tooltipCoord);
      });
    };

    const drawEnd = (evt) => {
      measureTooltipElement.className = 'ol-tooltip ol-tooltip-static';
      btnRemove = document.createElement('button');
      btnRemove.type = 'button';
      btnRemove.className = 'btnRemove';
      btnRemove.innerHTML = '지우기';
      measureTooltipElement.appendChild(btnRemove);
      btnRemove.onclick = (e) => this.removeFeature(evt, e);
      evt.feature.setProperties({ properties: { id: featureId } });

      featureId = featureId + 1;
      measureTooltip!.setOffset([0, -7]);
      // unset sketch
      this.sketch = null;
      // unset tooltip so that a new one can be created
      measureTooltipElement = null;
      this.createMeasureTooltip(status);
      unByKey(listener);
      // console.log(this.core.mapInstance.getOverlays().getArray())
    };

    const drawStart2 = () => {};

    const drawEnd2 = (evt) => {
      evt.feature.setProperties({ properties: { id: featureId } });
    };

    if (status) {
      this.draw = new Draw({
        source: this.source,
        type: type,
        condition: (e) => {
          return e.originalEvent.button === 2;
        },
        style: (feature) => {
          const geometryType = (feature.getGeometry() as any).getType();
          if (geometryType === type || geometryType === 'Point') {
            return this.style(type);
          }
        },
      });
      this.drawPoint = new Draw({
        source: this.pointSource,
        type: 'Point',
        condition: (e) => {
          return e.originalEvent.button === 2;
        },
      });
      this.core.mapInstance.addInteraction(this.draw);
      this.core.mapInstance.addInteraction(this.drawPoint);

      this.createMeasureTooltip(status);
      this.createHelpTooltip(status);

      this.draw.on('drawstart', drawStart);
      this.drawPoint.on('drawstart', drawStart2);

      this.draw.on('drawend', drawEnd);
      this.drawPoint.on('drawend', drawEnd2);

      this.polyVector.setStyle(this.pointVectorStyle);
      this.pointVector.setStyle(() => {
        return [
          new Style({
            image: new Circle({
              radius: 6,
              fill: new Fill({ color: '#fff' }),
              stroke: new Stroke({
                width: 4,
                color: type === 'Polygon' ? '#385EFA' : '#EB5B14',
              }),
            }),
            zIndex: 100,
          }),
        ];
      });

      const dogeunLayer = this.core.mapInstance.getAllLayers().find((layer) => layer.get('id') === 'DogeunLayer') as VectorLayer<VectorSource>;
      const triangleLayer = this.core.mapInstance.getAllLayers().find((layer) => layer.get('id') === 'TriangleLayer') as VectorLayer<VectorSource>;
      const assistantLayer = this.core.mapInstance.getAllLayers().find((layer) => layer.get('id') === 'AssistantLayer') as VectorLayer<VectorSource>;

      this.dogeunSnap = new Snap({
        source: dogeunLayer.getSource() as VectorSource,
      });
      this.core.mapInstance.addInteraction(this.dogeunSnap);
      this.triangleSnap = new Snap({
        source: triangleLayer.getSource() as VectorSource,
      });
      this.core.mapInstance.addInteraction(this.triangleSnap);
      this.assistantSnap = new Snap({
        source: assistantLayer.getSource() as VectorSource,
      });
      this.core.mapInstance.addInteraction(this.assistantSnap);
    } else {
      this.destroy(status);
    }
  }

  //레이어 초기화
  constructor(core: OlCore) {
    super(core);

    this.polyVector = new VectorLayer({
      source: this.source,
      zIndex: 1000,
    });
    this.pointVector = new VectorLayer({
      source: this.pointSource,
      zIndex: 1000,
    });

    this.core.mapInstance.addLayer(this.polyVector);
    this.core.mapInstance.addLayer(this.pointVector);

    // this.addInteraction()
    this.onPointerMove();
  }

  private onPointerMove() {
    this.core.mapInstance.on('pointermove', this.pointerMoveHandler);
    this.core.mapInstance.getViewport().addEventListener('mouseout', () => {
      helpTooltipElement?.classList.add('hidden');
    });
  }

  public destroy(status: boolean) {
    const drawInteraction = this.core.mapInstance
      .getInteractions()
      .getArray()
      .filter((i) => i instanceof Draw);
    this.core.mapInstance.removeInteraction(drawInteraction[0]);
    this.core.mapInstance.removeInteraction(drawInteraction[1]);

    //툴팁 제거
    this.createMeasureTooltip(status);
    this.createHelpTooltip(status);

    //폴리곤 소스 초기화
    this.source = new VectorSource();
    this.pointSource = new VectorSource();
    this.polyVector.setSource(this.source);
    this.pointVector.setSource(this.pointSource);

    //오버레이 제거
    this.core.mapInstance
      .getOverlays()
      .getArray()
      .slice(0)
      .forEach((overlay) => {
        if (overlay?.getProperties().element.outerHTML.includes('ol-tooltip')) {
          this.core.mapInstance.removeOverlay(overlay);
        }
      });

    //스냅 제거
    this.core.mapInstance.removeInteraction(this.dogeunSnap!);
    this.core.mapInstance.removeInteraction(this.triangleSnap!);
    this.core.mapInstance.removeInteraction(this.assistantSnap!);
    this.dogeunSnap = null;
    this.triangleSnap = null;
    this.assistantSnap = null;
  }

  private removeFeature(feature, e) {
    console.log(feature.feature);
    this.pointSource.getFeatures().map((i) => {
      if (i.getProperties().properties.id === feature.feature.getProperties().properties.id) {
        this.pointSource.removeFeature(i);
      }
    });
    e.target.parentNode.remove();
    this.source.removeFeature(feature.feature);
  }
}

export default MeasureModule;
