import { domainUrl, myKey, urlBuilder } from './common';

//*참고: https://www.vworld.kr/dtna/dtna_apiSvcFc_s001.do?apiNum=122
const paramsBuilder = ({ typenames, srs, bbox, startindex = 0 }) => {
  return {
    KEY: myKey, // * 필수값
    DOMAIN: domainUrl,
    TYPENAME: typenames?.toString(),
    BBOX: bbox?.toString(),
    RESULTTYPE: 'results',
    SRSNAME: srs, // EPSG:900913
    OUTPUT: 'application/json',
    STARTINDEX: startindex,
  };
};

// [공통] (포인트) 기준점 WFS url
export const getPointUrl = ({ key, bbox }: { key: 'Dogeun' | 'Assistant' | 'Triangle'; bbox: any[] }) => {
  let srsName = 'EPSG:5186'; // 피쳐 좌표계
  let params = {
    Dogeun: { typenames: 'dt_d007', url: 'getLgstspSpceWFS' }, // 지적도근점 sp
    Assistant: { typenames: 'dt_d008', url: 'getLgstgsSpceWFS' }, //지적삼각보조점 gs
    Triangle: { typenames: 'dt_d009', url: 'getLgstrgSpceWFS' }, // 지적삼각점 rg
  };

  return urlBuilder(`/ned/wfs/${params[key].url}`, paramsBuilder({ typenames: [params[key].typenames], srs: srsName, bbox }));
};
