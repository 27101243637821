import axios from 'axios';
import { domainUrl, myKey, urlBuilder, paramsBuilder } from './common';

// 행정 경계 Multipolygon WFS url
export const getMultiPolygonUrl = ({ type, bbox, startindex }: { type?: string; bbox?: any; startindex?: number }) => {
  let sido = 'lt_c_adsido_info'; // 광역시도
  let ri = 'lt_c_adri_info'; // 리
  let sigungu = 'lt_c_adsigg_info'; // 시군구
  let emd = 'lt_c_ademd_info'; //읍면동
  let bubun = 'lp_pa_cbnd_bubun'; // 연속 지적도

  let typenames = [type || ''];

  return urlBuilder('/req/wfs', paramsBuilder(typenames, 'EPSG:5186', bbox, startindex));
};

export const getBubun2Url = ({ bbox, startindex }: any) => {
  return urlBuilder('/ned/wfs/getCtnlgsSpceWFS', {
    VERSION: '1.1.0',
    TYPENAME: 'dt_d002',
    BBOX: bbox,
    SRSNAME: 'EPSG:5186',
    OUTPUT: 'application/json',
    KEY: myKey,
    //  STARTINDEX: startindex,
    //  filter: `<ogc:Filter>
    //        <ogc:And>
    //        ${
    //          startindex &&
    //          `<ogc:PropertyIsGreaterThan matchCase="true">
    //            <ogc:PropertyName>index</ogc:PropertyName>
    //            <ogc:Literal>${startindex}</ogc:Literal>
    //       </ogc:PropertyIsGreaterThan>`
    //        }
    //       <ogc:BBOX>
    //            <ogc:PropertyName>ag_geom</ogc:PropertyName>
    //            <Envelope srsDimension="2" srsName="EPSG:5186">
    //                 <lowerCorner>${bbox[0]} ${bbox[1]}</lowerCorner>
    //                 <upperCorner>${bbox[2]} ${bbox[3]}</upperCorner>
    //            </Envelope>
    //       </ogc:BBOX>
    //       </ogc:And>
    //  </ogc:Filter>`,
  });
};

// pnu 속성값으로 조회 - [연속지적도 WFS]
export const getBubunByPnu = async ({ pnu }) => {
  let url = urlBuilder('/ned/wfs/getCtnlgsSpceWFS', {
    VERSION: '1.1.0',
    TYPENAME: 'dt_d002',
    SRSNAME: 'EPSG:5186',
    OUTPUT: 'application/json',
    KEY: myKey,
    PNU: pnu,
  });
  let { data } = await axios.post(url);
  return data;
};
