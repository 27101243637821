import React from 'react';
import styled from 'styled-components';

const ToggleButtonStyle = {
  Wrap: styled.button`
    width: 38px;
    height: 20px;
    position: relative;
    border-radius: 4px;
    background: #dde3ec;
    &.active {
      background: #d1f1e6;
      > div {
        border: 2px solid #00b074;
        background: #00b07433;
        left: calc(100% - 18px);
      }
    }
  `,
  Icon: styled.div`
    width: 16px;
    height: 16px;
    box-sizing: border-box;
    border-radius: 2px;
    position: absolute;
    left: 2px;
    top: 2px;
    transition: 0.4s;
    border-color: #b5bdca;
    background: #b5bdca;
  `,
};

export interface ToggleButtonProps {
  onClick: () => void;
  active: boolean;
}
const ToggleButton = ({ onClick, active }: ToggleButtonProps) => {
  return (
    <ToggleButtonStyle.Wrap type={'button'} className={active ? 'active' : undefined} onClick={onClick}>
      <ToggleButtonStyle.Icon>
        <span className={'hidden'}></span>
      </ToggleButtonStyle.Icon>
    </ToggleButtonStyle.Wrap>
  );
};

export default ToggleButton;
