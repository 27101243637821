import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

export const InfoBoxSt = {
  Wrap: styled.div`
    position: absolute;
    right: 80px;
    bottom: 30px;
    z-index: 9;
    box-sizing: border-box;
    //max-width: 320px;
    ul {
      display: flex;
      flex-direction: column;
      li {
        display: flex;
        color: #272727;
        font-weight: 300;
        font-size: 16px;
        word-break: keep-all;
        &:not(:last-child) {
          margin-bottom: 16px;
        }
        > span {
          min-width: 74px;
          color: #656565;
          font-weight: 300;
          font-size: 16px;
        }
      }
    }
  `,
  Head: styled.div`
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #00b5b5;
    border-radius: 2px 2px 0 0;
    font-size: 16px;
    font-weight: 700;
    font-family: 'Pretendard';
    color: #fff;
    padding: 0 24px;
  `,
  Cont: styled.div`
    background: #fff;
    border: 2px solid #00b5b5;
    border-radius: 0 0 2px 2px;
  `,
  Inner: styled.div`
    padding: 20px 24px;
  `,
  Close: styled.button`
    position: relative;
    width: 20px;
    height: 20px;
    &:before,
    &:after {
      position: absolute;
      left: 50%;
      top: 50%;
      content: '';
      width: 24px;
      height: 1px;
      background: #fff;
    }
    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  `,
};

function InfoBox({ property, active, isInfoState, onCloseInfo }: { property: any; active: boolean; isInfoState: boolean; onCloseInfo: () => void }) {
  // property.pnu ->  시도(2) + 시군구(3) + 읍면동(3) + 리(2) + 필지구분(1) + 본번(4) + 부번(4) = 19자리
  console.log(property);

  return (
    <InfoBoxSt.Wrap style={{ display: active && isInfoState ? 'block' : 'none', bottom: 330 }}>
      <InfoBoxSt.Head>
        지적 정보
        <InfoBoxSt.Close type={'button'} onClick={onCloseInfo}>
          <span className={'hidden'}>닫기</span>
        </InfoBoxSt.Close>
      </InfoBoxSt.Head>
      <InfoBoxSt.Cont>
        <InfoBoxSt.Inner>
          <ul>
            <li>
              <span>주소</span> {property?.addr || property?.pnu || '-'}
            </li>
            <li>
              <span>지번</span> {property?.jibun || property?.lnm_lndcgr_smbol || '-'}
            </li>
            <li>
              <span>업데이트</span> {property?.last_updt_dt || '-'}
            </li>
            {/*<li>*/}
            {/*  <span>본번</span> {property?.bonbun || '-'}*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*  <span>부번</span> {property?.bubun || '-'}*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*  <span>기준년</span> {property?.gosi_year || '-' || '-'}*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*  <span>기준월</span> {property?.gosi_month || '-'}*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*  <span>지가</span> {Number(property?.jiga).toLocaleString('ko-KR') || '-'}*/}
            {/*</li>*/}
          </ul>
        </InfoBoxSt.Inner>
      </InfoBoxSt.Cont>
    </InfoBoxSt.Wrap>
  );
}

export default InfoBox;
