import { Style, RegularShape, Stroke, Fill, Circle, Text } from 'ol/style';

const stroke = new Stroke({ color: 'black', width: 2 });
const fill = new Fill({ color: '#55ff00' });

export const images = {
  circle_1: new Circle({
    radius: 5,
    fill: new Fill({
      color: '#ff7700ff',
    }),
    stroke: stroke,
  }),
  circle_2: new Circle({
    radius: 5,
    fill: new Fill({
      color: '#ffb700ff',
    }),
    stroke: stroke,
  }),
  circle_3: new Circle({
    radius: 5,
    fill: new Fill({
      color: '#ff1900ff',
    }),
    stroke: stroke,
  }),
  square: new RegularShape({
    fill: fill,
    stroke: stroke,
    points: 4,
    radius: 10,
    angle: Math.PI / 4,
  }),
  triangle: new RegularShape({
    fill: new Fill({ color: '#00fffb' }),
    stroke: stroke,
    points: 3,
    radius: 10,
    // rotation: Math.PI / 4,
    angle: 0,
  }),
  rectangle: new RegularShape({
    fill: fill,
    stroke: stroke,
    radius: 10 / Math.SQRT2,
    radius2: 10,
    points: 4,
    angle: 0,
    scale: [1, 0.5],
  }),
  star: new RegularShape({
    fill: fill,
    stroke: stroke,
    points: 5,
    radius: 10,
    radius2: 4,
    angle: 0,
  }),
  cross: new RegularShape({
    fill: fill,
    stroke: stroke,
    points: 4,
    radius: 10,
    radius2: 0,
    angle: 0,
  }),
  x: new RegularShape({
    fill: fill,
    stroke: stroke,
    points: 4,
    radius: 10,
    radius2: 0,
    angle: Math.PI / 4,
  }),
};

export const getImage = (shp, color) => {
  const fill = new Fill({ color });
  const images = {
    circle: new Circle({
      radius: 5,
      fill,
      stroke,
    }),
    square: new RegularShape({
      fill,
      stroke,
      points: 4,
      radius: 10,
      angle: Math.PI / 4,
    }),
    triangle: new RegularShape({
      fill,
      stroke,
      points: 3,
      radius: 10,
      // rotation: Math.PI / 4,
      angle: 0,
    }),
  };
  return images[shp];
};

// [shp] : circle, square, triangle
export const shapeStyle = (feature, shp, color) => {
  const properties = feature?.getProperties();

  const text = properties?.a5 || '-'; // GeoServer 도근점명

  return [
    new Style({
      image: getImage(shp, color),
      text: new Text({
        text: String(text),
        font: '400 16px NotoSans',
        stroke: new Stroke({
          color: '#000',
          width: 1,
        }),
        fill: new Fill({ color: '#ffffff' }),
        offsetY: 10,
      }),
    }),
  ];
};

// with text
export const withTextStyle = (feature, isActive = false) => {
  let properties = feature?.getProperties();
  let id = feature.getId(); // 도근점, 삼각보조점, 삼각점 구분

  let shp = '';
  let color = '';
  let txt = '';

  if (id.includes('d007')) {
    shp = 'circle';
    color = '#ff7700ff';
    txt = properties?.lgstr_splmcpt_idntfc_nm || properties?.a5; // 도근점
  }
  if (id.includes('d008')) {
    shp = 'square';
    color = '#55ff00';
    txt = properties?.lgstr_tgspt_idntfc_nm; // 삼각보조점
  }
  if (id.includes('d009')) {
    shp = 'triangle';
    color = '#00fffb';
    txt = properties?.lgstr_trgltpt_no_nm; // 삼각점
  }

  let text = new Text({
    text: String(txt),
    font: '400 16px NotoSans',
    fill: new Fill({ color: '#ffffff' }),
    stroke: new Stroke({
      color: '#000',
      width: 1,
    }),
    offsetY: 10,
  });

  return [
    new Style({
      image: getImage(shp, isActive ? '#ff1900ff' : color),
      text: text,
      zIndex: isActive ? 2 : 1,
    }),
  ];
};
