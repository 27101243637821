import React, { useState } from 'react';
import styled from 'styled-components';
import IcoMapType from '../../../assets/images/toolbar/ico-mapType.svg';
import IcoMapTypeActive from '../../../assets/images/toolbar/ico-mapType-active.svg';

const MapTypeStyle = {
  Wrap: styled.div`
    position: relative;
    margin-bottom: 18px;
  `,
  ToggleBtn: styled.button`
    width: 42px;
    height: 42px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: #fff url(${IcoMapType}) no-repeat center;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
    &.active,
    &:hover {
      background-image: url(${IcoMapTypeActive});
    }
  `,
  List: styled.ul`
    position: absolute;
    overflow: hidden;
    left: calc(100% + 6px);
    top: 0;
    border-radius: 4px;
    width: 96px;
  `,
  Item: styled.li`
    padding: 0 16px;
    display: flex;
    background: #fff;
    height: 36px;
    align-items: center;
    justify-content: flex-start;
    color: #3f3f3f;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    &.active {
      background: #d1f1e6;
    }
  `,
};

const MapType = ({ mapInstance }) => {
  const [show, setShow] = useState(false);
  const [mapTypeList, setMapTypeList] = useState([
    { id: 0, name: '일반 지도', active: false },
    { id: 1, name: '위성 지도', active: true },
  ]);

  // 지도 전환
  const onChangeMapType = (id: number) => {
    const newArr = [...mapTypeList];
    newArr.map((i) => {
      i.active = i.id === id;
    });
    setMapTypeList(newArr);
    setShow(false);
    id === 0 ? mapInstance?.mapChange(mapInstance!.osmMapSource) : mapInstance?.mapChange(mapInstance!.googleMapSource);
  };
  return (
    <MapTypeStyle.Wrap>
      <MapTypeStyle.ToggleBtn type={'button'} className={show ? 'active' : undefined} onClick={() => setShow(!show)}></MapTypeStyle.ToggleBtn>
      {show ? (
        <MapTypeStyle.List>
          {mapTypeList.map((item) => {
            return (
              <MapTypeStyle.Item key={item.id} className={item.active ? 'active' : ''} onClick={() => onChangeMapType(item.id)}>
                {item.name}
              </MapTypeStyle.Item>
            );
          })}
        </MapTypeStyle.List>
      ) : null}
    </MapTypeStyle.Wrap>
  );
};

export default MapType;
