import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import IcoUser from 'assets/images/map/ico-user.svg';
import PopupPasswordChange from '../../../popup/PopupPasswordChange';
import usePopupToggle from '../../../hooks/custom/usePopupToggle';

import IcoPassword from 'assets/images/userSetting/ico-pw-change.svg';
import IcoLogout from 'assets/images/userSetting/ico-logout.svg';
import { useOutsideClick } from 'util/useOutsideClick';

const UserSettingSt = {
  Wrap: styled.div`
    position: relative;
  `,
  Button: styled.div`
    /* margin-bottom: 6px; */
    width: 48px;
    height: 48px;
    background-color: #ffffff;
    border: 2px solid #ffffff;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.active {
      border: 2px solid #00b074;
    }
    .icoUser {
      display: inline-block;
      width: 24px;
      height: 24px;
      background: url(${IcoUser}) no-repeat center;
    }
  `,
  MenuWrap: styled.div`
    position: absolute;
    top: 54px;
    right: 0;
    width: 196px;
    border-radius: 4px;
    background-color: #fff;
    overflow: hidden;
    /* transform: scaleY(0); */
    /* transform-origin: top; */
    opacity: 0;
    height: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    &.open {
      opacity: 1;
      /* height: max-content; */
      height: 163px;
      visibility: visible;
      /* transform: scaleY(1); */
    }
    .userInfo {
      height: 75px;
      box-sizing: border-box;
      padding: 16px 20px;
      border-bottom: 1px solid #d9d9d9;
      pointer-events: none;
      p {
        color: #01555e;
      }
      .name {
        margin-bottom: 7px;
        font-size: 16px;
        font-weight: 600;
      }
      .email {
        font-weight: 400;
      }
    }
    ul {
      li {
        min-height: 44px;
        padding-left: 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        /* justify-content: center; */
        /* flex-direction: column; */
        cursor: pointer;
        p {
          color: #3f3f3f;
          font-weight: 500;
        }
        &:hover {
          transition: all 0.3s;
          background-color: #f1f1f1;
        }
        .ico {
          margin-right: 8px;
          display: block;
          width: 20px;
          height: 20px;
        }
        &.changePw {
          .ico {
            background: url(${IcoPassword}) no-repeat center;
          }
        }
        &.logout {
          .ico {
            background: url(${IcoLogout}) no-repeat center;
          }
        }
      }
    }
  `,
};

function UserSetting() {
  const menuRef = useRef(null);
  const [showMenu, setShowMenu] = useState(false); // 유저 메뉴 오픈
  const [popupState, setPopupState] = useState({
    changePassword: false,
  });
  const onTogglePopup = usePopupToggle(popupState, setPopupState);

  // 외부영역 클릭 이벤트 - 유저메뉴닫기
  useOutsideClick(() => setShowMenu(false), menuRef);
  return (
    <>
      <UserSettingSt.Wrap ref={menuRef}>
        <UserSettingSt.Button className={`${showMenu && 'active'}`} onClick={() => setShowMenu(!showMenu)}>
          <span className={`icoUser`}></span>
        </UserSettingSt.Button>

        {/* 유저 메뉴 목록 */}
        <UserSettingSt.MenuWrap className={`${showMenu && 'open'} `}>
          <div className="userInfo">
            <p className="name">김무브</p>
            <p className="email">kmv.krel@krelez.co.kr</p>
          </div>
          <ul>
            <li className="changePw" onClick={() => onTogglePopup('changePassword')}>
              <span className="ico"></span>
              <p>비밀번호 변경</p>
            </li>
            <li className="logout">
              <span className="ico"></span>
              <p>로그아웃</p>
            </li>
          </ul>
        </UserSettingSt.MenuWrap>
        {/* 비밀번호 변경 팝업 */}
        {popupState.changePassword ? <PopupPasswordChange onClose={() => onTogglePopup('changePassword')} /> : null}
      </UserSettingSt.Wrap>
    </>
  );
}

export default UserSetting;
