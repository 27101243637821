import React, { ReactNode } from 'react';
import styled from 'styled-components';

export const LabelValueStyle = {
  Wrap: styled.div<LabelValueProps>`
    display: flex;
    align-items: ${(p) => (p.alignItem === 'flex-start' ? 'flex-start' : 'center')};
    &:not(:last-child) {
      margin-bottom: ${(p) => (typeof p.marginBottom === 'number' ? p.marginBottom + 'px' : '6px')};
    }
    box-sizing: border-box;
    border-bottom: ${(p) => (p.border ? '1px solid rgba(217, 217, 217, 0.62)' : 'none')};
    padding: ${(p) => (p.padding ? p.padding + 'px 0 ' + p.padding + 'px' : 0)};
  `,
  Key: styled.strong<LabelValueProps>`
    display: flex;
    align-items: center;
    min-width: ${(p) => (p.labelWidth ? p.labelWidth + 'px' : '74px')};
    font-size: ${(p) => (p.fontSize ? p.fontSize + 'px' : '14px')};
    font-weight: 500;
    color: #00b074;
    margin-right: 4px;
  `,
  Value: styled.span<LabelValueProps>`
    display: flex;
    align-items: center;
    flex: 1;
    word-break: keep-all;
    font-size: ${(p) => (p.fontSize ? p.fontSize + 'px' : '14px')};
    font-weight: 500;
    ${(p) =>
      p.$ellipsis &&
      `
        overflow:hidden;text-overflow:ellipsis;white-space:noWrap;
      `};
  `,
};

export interface LabelValueProps {
  labelWidth?: number;
  fontSize?: number;
  marginBottom?: number;
  alignItem?: 'flex-start';
  $ellipsis?: boolean;
  label?: string;
  value?: string | number | ReactNode;
  padding?: number;
  border?: boolean;
}

const LabelValue = ({ labelWidth, fontSize, marginBottom, alignItem, $ellipsis, label, value, padding, border }: LabelValueProps) => {
  return (
    <LabelValueStyle.Wrap marginBottom={marginBottom} alignItem={alignItem} border={border} padding={padding}>
      <LabelValueStyle.Key labelWidth={labelWidth} fontSize={fontSize}>
        {label}
      </LabelValueStyle.Key>
      <LabelValueStyle.Value $ellipsis={$ellipsis} border={border}>
        {value}
      </LabelValueStyle.Value>
    </LabelValueStyle.Wrap>
  );
};

export default LabelValue;
