import VectorModule from './VectorModule';
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { transform } from 'ol/proj';
import { baseProjection, targetProjection, transProjection } from 'ol/projection';

class PointModule extends VectorModule {
  // 단일 피쳐 생성 (속성 정보 추가)
  public createPointFeature(data: any) {
    const { bbox, geometry, id, properties } = data;
    //  console.log('coord', geometry?.coordinates, id);

    let coord;
    // coordinates -> undefined 에러 발생
    if (geometry?.coordinates) {
      coord = data.geometry.coordinates;
    }

    const feature = new Feature({
      // geometry: coord ? new Point(coord) : undefined,
      // properties: { ...properties, id, bbox },
    });
    // geometry
    feature.setGeometry(coord ? new Point(coord) : undefined);
    // properties (* 속도 저하..(select시 일치하는 항목 찾는데에 오래 걸림!))
    feature.setProperties({ ...properties, id, bbox });

    // id 부여
    feature.setId(id);
    // feature 좌표 변환
    feature.getGeometry()?.transform(targetProjection, baseProjection); // srs -> 4326

    return feature;
  }

  // 피쳐 배열 리턴
  public createPointFeatures(datas: any[]) {
    return datas?.map((data) => {
      return this.createPointFeature(data);
    });
  }
}

export default PointModule;
