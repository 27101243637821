import OlCore from 'ol/OlCore';
import VectorModule from 'ol/Layer/VectorModule';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import GeoJSONFormat from 'ol/format/GeoJSON';
import * as Format from 'ol/format';
import * as loadingstrategy from 'ol/loadingstrategy';
import { Circle, Fill, Stroke, Style, Text } from 'ol/style.js';
import { getBubun2Url, getMultiPolygonUrl } from 'service/multiPolygon';
import { getPointUrl } from 'service/point';

import { Feature } from 'ol';
import { FeatureLike } from 'ol/Feature';
import xhr2HttpRequest from 'ol/util/xhr2HttpRequest';

import GreaterThanOrEqualTo from 'ol/format/filter/GreaterThanOrEqualTo';
import { Listener } from 'ol/events';

// 연속 지적도 테스트용 레이어
class ObjectPolygon extends VectorModule {
  private PolygonLayer: VectorLayer<VectorSource>;
  private SidoLayer: VectorLayer<VectorSource>;
  private SigunguLayer: VectorLayer<VectorSource>;
  private EmdLayer: VectorLayer<VectorSource>;
  private BubunLayer: VectorLayer<VectorSource>;
  private BubunLayer2: VectorLayer<VectorSource>;

  private SidoSource!: VectorSource;
  private SigunguSource!: VectorSource;
  private EmdSource!: VectorSource;
  //   private BubunSource!: VectorSource;
  private BubunSource!: any;
  private BubunSource2!: any;

  private mapClick!: Listener;

  constructor(core: OlCore) {
    super(core);

    //클릭한 용지경계
    this.PolygonLayer = new VectorLayer<VectorSource>({
      properties: {
        id: 'PolygonLayer',
      },
      visible: true,
      zIndex: 8,
      // minResolution: 0,
      // maxResolution: 0.0001,
      minZoom: 16.5,
      maxZoom: Infinity,
    });
    this.SidoLayer = new VectorLayer<VectorSource>({
      properties: {
        id: 'SidoLayer',
      },
      visible: true,
      zIndex: 4,
      minZoom: 7.99,
      maxZoom: 11.99,
    });
    this.SigunguLayer = new VectorLayer<VectorSource>({
      properties: {
        id: 'SigunguLayer',
      },
      visible: true,
      zIndex: 3,
      minZoom: 11.99,
      maxZoom: 13.99,
    });
    this.EmdLayer = new VectorLayer<VectorSource>({
      properties: {
        id: 'EmdLayer',
      },
      visible: true,
      zIndex: 3,
      minZoom: 13.99,
      // maxZoom: 16.99,
    });
    this.BubunLayer = new VectorLayer<VectorSource>({
      properties: {
        id: 'BubunLayer',
      },
      visible: true,
      zIndex: 7,
      minZoom: 16.5,
      maxZoom: Infinity,
    });
    this.BubunLayer2 = new VectorLayer<VectorSource>({
      properties: {
        id: 'BubunLayer2',
      },
      visible: true,
      zIndex: 3,
      minZoom: 16.5,
      maxZoom: Infinity,
    });

    this.setLayers([this.PolygonLayer, this.SidoLayer, this.SigunguLayer, this.EmdLayer, this.BubunLayer, this.BubunLayer2]);
  }

  // 폴리곤 스타일
  private getStyle(type?: string, feature?: FeatureLike) {
    if (type === 'sido') {
      return new Style({
        fill: new Fill({ color: 'rgba(249, 146, 248, 0.2)' }),
        stroke: new Stroke({ color: '#E76C6C', width: 2 }),
        text: new Text({
          textAlign: 'center',
          font: '20px Verdana',
          scale: 1,
          text: feature?.getProperties()?.ctp_kor_nm || '',
          fill: new Fill({ color: '#000' }),
          stroke: new Stroke({ color: '#fff', width: 3 }),
        }),
      });
    }
    if (type === 'sigun') {
      return new Style({
        fill: new Fill({ color: 'rgba(51,163,255,.2)' }),
        stroke: new Stroke({ color: '#33A3FF', width: 2 }),
        text: new Text({
          textAlign: 'center',
          font: '20px Verdana',
          scale: 1,
          text: feature?.getProperties()?.full_nm || '',
          fill: new Fill({ color: '#000' }),
          stroke: new Stroke({ color: '#fff', width: 3 }),
        }),
      });
    }
    if (type === 'emd')
      return new Style({
        // fill: new Fill({ color: 'rgba(255, 118, 0, 0.2)' }),
        stroke: new Stroke({ color: '#FF7600', width: 2 }),
        text: new Text({
          textAlign: 'center',
          font: '20px Verdana',
          scale: 1,
          text: feature?.getProperties()?.full_nm || '',
          fill: new Fill({ color: '#000' }),
          stroke: new Stroke({ color: '#fff', width: 3 }),
        }),
      });
    if (type === 'bubun')
      return new Style({
        fill: new Fill({ color: 'rgba(165, 79, 232, 0.2)' }),
        stroke: new Stroke({ color: 'rgba(255, 255, 255, .5)', width: 2 }),
        text: new Text({
          textAlign: 'center',
          font: '14px Verdana',
          scale: 1,
          text: feature?.getProperties()?.jibun || '',
          fill: new Fill({ color: '#000' }),
          stroke: new Stroke({ color: '#fff', width: 3 }),
        }),
      });
    if (type === 'bubunActive')
      return new Style({
        fill: new Fill({ color: 'rgba(165, 79, 232, 0.4)' }),
        stroke: new Stroke({ color: 'rgba(255, 255, 255, 1)', width: 2 }),
        text: new Text({
          textAlign: 'center',
          font: '14px Verdana',
          scale: 1,
          text: feature?.getProperties()?.jibun || '',
          fill: new Fill({ color: '#000' }),
          stroke: new Stroke({ color: '#fff', width: 3 }),
        }),
      });
    if (type === 'bubunHover')
      return new Style({
        fill: new Fill({ color: 'rgba(165, 79, 232, 0.3)' }),
        stroke: new Stroke({ color: 'rgba(255, 255, 255, .5)', width: 2 }),
        text: new Text({
          textAlign: 'center',
          font: '14px Verdana',
          scale: 1,
          text: feature?.getProperties()?.jibun || '',
          fill: new Fill({ color: '#000' }),
          stroke: new Stroke({ color: '#fff', width: 3 }),
        }),
      });
  }

  // 연속지적도 (클릭한 용지 경계) - featureCollection
  public drawActive(featureCollection: any) {
    if (featureCollection) {
      const geoJson = featureCollection?.features?.[0];
      //  const properties = geoJson?.properties;

      let features = new GeoJSONFormat()?.readFeatures(geoJson);

      // feature 들을 담을 vector source
      let vectorSource = new VectorSource({
        features: features,
      });

      //  for (var i in features) features[i].setStyle(style);
      this.PolygonLayer.setStyle((feature) => this.getStyle('bubunActive', feature));

      // vector layer
      this.PolygonLayer.setSource(vectorSource);
      this.PolygonLayer.setVisible(true);
    } else {
      this.PolygonLayer.setVisible(false);
    }
  }

  //
  public drawInit() {
    this.PolygonLayer.setStyle(new Style());
  }

  // 행정 경계 그리기(시도)
  public drawSido() {
    // 시도 벡터소스
    // 용량이 크고 bbox의미가 없어 초기 한번만 렌더링 후 캐시사용
    this.SidoSource = new VectorSource({
      format: new GeoJSONFormat(),
      url: function (extent) {
        var strUrl = getMultiPolygonUrl({ type: 'lt_c_adsido_info' });
        return strUrl;
      },
      // strategy: loadingstrategy.bbox,
    });
    // 시도
    this.SidoLayer.setStyle((feature) => {
      return this.getStyle('sido', feature);
    });

    // vector layer
    this.SidoLayer.setSource(this.SidoSource);
  }

  // 행정 경계 그리기(시군구)
  public drawSigungu() {
    // 시군구 벡터소스
    this.SigunguSource = new VectorSource({
      // url: function (extent) {
      //   console.log(extent); // coordinate []
      //   var strUrl = getMultiPolygonUrl({ type: 'lt_c_adsigg_info', bbox: extent });
      //   return strUrl;
      // },
      format: new GeoJSONFormat(),
      loader: (extent, resolution, projection, success: any, failure: any) => {
        let newZoom = this.core.mapInstance.getView()?.getZoomForResolution(resolution) || 20;

        if (12 <= newZoom && newZoom <= 14) {
          //  const proj = projection.getCode();
          // const url = 'https://ahocevar.com/geoserver/wfs?service=WFS&' +
          //     'version=1.1.0&request=GetFeature&typename=osm:water_areas&' +
          //     'outputFormat=application/json&srsname=' + proj + '&' +
          //     'bbox=' + extent.join(',') + ',' + proj;
          const strUrl = getMultiPolygonUrl({ type: 'lt_c_adsigg_info', bbox: extent }); // API

          const xhr = new XMLHttpRequest(); // request 객체 생성
          // 요청에 대한 응답 결과 처리
          xhr.onreadystatechange = () => {
            if (xhr.readyState === 1) {
              console.log('loading....');
            }
            if (xhr.readyState == 4) {
              console.log('done');
              if (xhr.status == 200) {
                var txt = xhr.responseText; //txt를 사용해서 알맞은 작업 수행
              }
              if (xhr.status == 500) {
              }
            }
          };

          xhr.open('GET', strUrl); // 요청 초기화 readyState = 1

          const onError = () => {
            this.SigunguSource.removeLoadedExtent(extent);
            failure();
          };
          xhr.onerror = onError; // 에러 처리

          xhr.onload = () => {
            // readyState = 4
            if (xhr.status === 200) {
              const result = JSON.parse(xhr.responseText);
              if (result?.type === 'FeatureCollection') {
                const features = new GeoJSONFormat().readFeatures(xhr.responseText);
                this.SigunguSource.addFeatures(features); // 피쳐 -> 벡터 소스
                success(features);
              } else {
                console.log(result);
              }
            } else {
              onError();
            }
          };
          xhr.send(); // 서버에 요청
        } else {
          //  this.SigunguSource?.clear();
        }
      },

      strategy: loadingstrategy.bbox,
    });

    // 시군구
    //  this.SigunguLayer.setStyle(this.getStyle('sido'));
    this.SigunguLayer.setStyle((feature) => this.getStyle('sigun', feature));

    // vector layer
    this.SigunguLayer.setSource(this.SigunguSource);
    this.SigunguLayer.setVisible(true);
  }

  // 행정 경계 그리기(읍면동)
  public drawEmd() {
    // 읍면동 벡터소스
    this.EmdSource = new VectorSource({
      format: new GeoJSONFormat(),
      url: function (extent) {
        var strUrl = getMultiPolygonUrl({ type: 'lt_c_ademd_info', bbox: extent });
        return strUrl;
      },
      strategy: loadingstrategy.bbox,
    });

    // 읍면동
    this.EmdLayer.setStyle((feature) => this.getStyle('emd', feature));

    // vector layer
    this.EmdLayer.setSource(this.EmdSource);
  }

  // 연속 지적도
  public drawBubun(state: boolean) {
    // toggle ON
    if (state) {
      // 벡터 소스
      this.BubunSource = new VectorSource({
        format: new GeoJSONFormat(),
        //   url: function (extent) {
        //     var strUrl = getMultiPolygonUrl({ type: 'lp_pa_cbnd_bubun', bbox: extent });
        //     return strUrl;
        //   },
        loader: (extent, resolution, projection, success: any, failure: any) => {
          var strUrl = getMultiPolygonUrl({ type: 'lp_pa_cbnd_bubun', bbox: extent });

          const xhr = new XMLHttpRequest();
          xhr.open('GET', strUrl);

          const onError = () => {
            this.BubunSource.removeLoadedExtent(extent);
            failure(); // 실패 처리
          };
          xhr.onerror = onError;

          xhr.onload = async () => {
            if (xhr.status == 200) {
              // this.BubunSource.clear(); // 초기화 후 다시 담기

              // 응답 response
              const response = JSON.parse(xhr.response);
              let totalFeatures = response?.totalFeatures;
              let count = totalFeatures / 1000;

              // totalFeatures >= 1000일 때 요청
              for (let i = 1; i < count; i++) {
                let startindex = 1000 * i;

                let strUrl = getMultiPolygonUrl({ type: 'lp_pa_cbnd_bubun', bbox: extent, startindex: startindex });

                // [공통] 데이터 재요청 코드
                xhr2HttpRequest({ method: 'GET', url: strUrl, vectorSource: this.BubunSource }); // http Request
              }

              //피쳐 생성 후 추가
              const features: any = this.BubunSource.getFormat().readFeatures(xhr.responseText);
              this.BubunSource.addFeatures(features);
              success(features); // 성공 처리
            } else {
              onError();
            }
          };
          xhr.send(); // 첫번쨰 요청
        },
        strategy: loadingstrategy.bbox,
      });

      // 레이어 스타일
      this.BubunLayer.setStyle((feature) => this.getStyle('bubun', feature));

      // vector layer
      this.BubunLayer.setSource(this.BubunSource);
      this.BubunLayer.setVisible(true);
    } else {
      // toggle OFF
      this.PolygonLayer.setVisible(false);
      this.BubunLayer.setVisible(false);
    }
  }

  // TODO: ned/wfs
  public drawBubun2() {
    this.BubunSource2 = new VectorSource({
      format: new GeoJSONFormat(),
      //   url: function (extent) {
      //     var strUrl = getMultiPolygonUrl({ type: 'lp_pa_cbnd_bubun', bbox: extent });
      //     return strUrl;
      //   },
      loader: (extent, resolution, projection, success: any, failure: any) => {
        var strUrl = getBubun2Url({ bbox: extent });

        const xhr = new XMLHttpRequest();
        xhr.open('POST', strUrl);

        const onError = () => {
          this.BubunSource2.removeLoadedExtent(extent);
          failure(); // 실패 처리
        };
        xhr.onerror = onError;

        xhr.onload = async () => {
          if (xhr.status == 200) {
            // this.BubunSource.clear(); // 초기화 후 다시 담기

            // 응답 response
            const response = JSON.parse(xhr.response);
            let totalFeatures = response?.totalFeatures;
            let count = totalFeatures / 1000;

            // totalFeatures >= 1000일 때 요청
            for (let i = 1; i < count; i++) {
              let startindex = 1000 * i;
              console.log(startindex);
              //   let filter2 = new GreaterThanOrEqualTo('id', startindex);

              let strUrl = getBubun2Url({ bbox: extent, startindex: startindex });
              // [공통] 데이터 재요청 코드
              xhr2HttpRequest({ url: strUrl, vectorSource: this.BubunSource2, method: 'POST' }); // http Request
            }

            //피쳐 생성 후 추가
            const features: any = this.BubunSource2.getFormat().readFeatures(xhr.responseText);
            this.BubunSource2.addFeatures(features);
            success(features); // 성공 처리
          } else {
            onError();
          }
        };
        xhr.send(); // 첫번쨰 요청
      },
      strategy: loadingstrategy.bbox,
    });

    // 레이어 스타일
    this.BubunLayer2.setStyle((feature) => this.getStyle('bubun', feature));

    // vector layer
    this.BubunLayer2.setSource(this.BubunSource2);
    this.BubunLayer2.setVisible(true);
  }

  // 클릭한 위치 위경도
  public getCoord(callback?: any) {
    const event = (evt: any) => {
      let x = evt.coordinate[0];
      let y = evt.coordinate[1];

      if (callback) {
        //   callback([x, y]);
        callback({ x, y });
      }
    };
    this.core.mapInstance.addEventListener('click', event);
  }

  // 지도 클릭 이벤트
  public addMapEvent(callback?: any) {
    this.core.mapInstance.removeEventListener('click', this.mapClick); // 초기화

    this.mapClick = (evt: any) => {
      // console.log(evt.target?.getFeatures());
      let feature = this.core.mapInstance.forEachFeatureAtPixel(evt.pixel, function (feature: any) {
        return feature;
      });

      if (feature) {
        let id = feature.getId();
        let properties = feature.getProperties();

        // callback && callback
        callback && callback(properties); // 연속지적도 폴리곤 클릭시, 해당 피쳐 전달
      } else {
        // 일반 지도 영역 클릭시
        callback && callback(undefined);
      }
    };

    this.core.mapInstance.addEventListener('click', this.mapClick);
  }

  // 지도 줌레벨 이벤트
  public addZoomEvent() {
    var currZoom = this.core.mapInstance.getView().getZoom();

    this.core.mapInstance.on('moveend', (evt?: any) => {
      // //TEST:
      // let length = this.BubunSource?.getFeatures();
      // console.log(length);
      //
      var newZoom = this.core.mapInstance.getView().getZoom() || 0;
      var newResolution = this.core.mapInstance.getView().getResolution();
      console.log('newZoom', newZoom);
      console.log('newResolution', newResolution);

      // 기본 줌 텍스트 스타일
      document.getElementById('zoomLevel')!.style.color = 'black';
      document.getElementById('zoomLevel')!.innerHTML = String(newZoom) || '';

      // 호출
      // this.drawWFS();

      // 사군구 레이어
      if (12 <= newZoom && newZoom <= 14) {
        //   this.SigunguLayer.setVisible(true);
        document.getElementById('zoomLevel')!.style.color = '#2339ff';
      } else {
        //   this.SigunguSource.clear();
        //   this.SigunguLayer.setVisible(false);
      }

      if (14 <= newZoom && newZoom <= 17) {
        //   this.EmdLayer.setVisible(true);
        document.getElementById('zoomLevel')!.style.color = '#ff099d';
      } else {
        //   this.EmdLayer.setVisible(false);
      }

      if (17 <= newZoom) {
        //   this.BubunLayer.setVisible(true);
        document.getElementById('zoomLevel')!.style.color = '#ff0909';
      } else {
        //   this.BubunLayer.setVisible(false);
      }
    });
    //  this.core.mapInstance.getView().on('change:resolution', (event) => {
    //    var newZoom = this.core.mapInstance.getView().getZoom() || 0;
    //    var newResolution = this.core.mapInstance.getView().getResolution();
    //    console.log('zoom changed', newZoom);
    //    console.log('resolution changed', newResolution);

    //    document.getElementById('zoomLevel')!.innerHTML = String(newZoom) || '';

    //    if (newZoom >= 13) {
    //      this.drawWFS();
    //      document.getElementById('zoomLevel')!.style.color = 'red';
    //    } else {
    //      this.SigunguSource.clear();
    //      document.getElementById('zoomLevel')!.style.color = 'black';
    //    }
    //  });
  }

  // 마우스 무브 이벤트
  public addMapHoverEvent() {
    const thisStyle = (feature) => this.getStyle('bubunHover', feature);
    let selected: any = null;
    this.core.mapInstance.on('pointermove', (evt) => {
      if (selected !== null) {
        selected?.setStyle(undefined);
        selected = null;
      }

      let feature = this.core.mapInstance.forEachFeatureAtPixel(evt.pixel, function (feature: any) {
        if (feature?.getId()?.includes('bubun')) {
          selected = feature;
          feature.setStyle(thisStyle);
          return feature;
        } else {
          return null;
        }
      });

      if (feature) {
        document.body.style.cursor = 'pointer';
      } else {
        document.body.style.cursor = 'default';
      }
    });
  }

  public getCurrZoom() {
    return this.core.mapInstance.getView().getZoom() || 0;
  }
}
export default ObjectPolygon;
