import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { confirmState, loadingState } from '../store/Atoms';
import PopupPasswordChange from '../popup/PopupPasswordChange';
import PopupLotInfo from '../popup/PopupLotInfo';
import PopupDuplicationPic from '../popup/PopupDuplicationPic';
import PopupFailUpload from '../popup/PopupFailUpload';
import PopupResult from '../popup/PopupResult';
import { useNavigate } from 'react-router-dom';

const TestViewList = () => {
  const [confirmStatus, setConfirmStatus] = useRecoilState(confirmState);
  const [loading, setLoading] = useRecoilState(loadingState);
  const [popupStatus, setPopupStatus] = useState({
    password: false,
    lotInfo: false,
    dupl: false,
    errorUpload: false,
    totalResult: false,
  });
  return (
    <>
      <select
        onChange={(e) => {
          const value = e.target.value;
          if (value === '1') {
            setLoading({ show: true });
            setTimeout(() => {
              setLoading({ show: false });
            }, 3000);
          } else if (value === '2') {
            setConfirmStatus((prev) => {
              const leftFunc = () => {
                alert('취소');
                setConfirmStatus((prev) => {
                  return { ...prev, show: false };
                });
              };
              const rightFunc = () => {
                alert('예');
                setConfirmStatus((prev) => {
                  return { ...prev, show: false };
                });
              };
              return {
                ...prev,
                show: true,
                message: '로그아웃 하시겠습니까?',
                leftText: '취소',
                rightText: '예',
                leftFunc: leftFunc,
                rightFunc: rightFunc,
              };
            });
          } else if (value === '3') {
            setPopupStatus({ ...popupStatus, password: true });
          } else if (value === '4') {
            setPopupStatus({ ...popupStatus, lotInfo: true });
          } else if (value === '5') {
            setPopupStatus({ ...popupStatus, dupl: true });
          } else if (value === '6') {
            setPopupStatus({ ...popupStatus, errorUpload: true });
          } else if (value === '7') {
            setPopupStatus({ ...popupStatus, totalResult: true });
          } else if (value === '8') {
            window.location.href = '/login';
          }
        }}
      >
        <option selected={true}>선택</option>
        <option value={'1'}>프로그레스 바</option>
        <option value={'2'}>공통 얼럿/컨펌 팝업</option>
        <option value={'3'}>비밀번호 변경</option>
        <option value={'4'}>필지 정보</option>
        <option value={'5'}>사진 중복 확인</option>
        <option value={'6'}>업로드 실패</option>
        <option value={'7'}>전체 결과 보기</option>
        <option value={'8'}>로그인페이지 이동</option>
      </select>
      {popupStatus.password ? <PopupPasswordChange onClose={() => setPopupStatus({ ...popupStatus, password: false })} /> : null}
      {popupStatus.lotInfo ? <PopupLotInfo onClose={() => setPopupStatus({ ...popupStatus, lotInfo: false })} /> : null}
      {popupStatus.dupl ? <PopupDuplicationPic onClose={() => setPopupStatus({ ...popupStatus, dupl: false })} /> : null}
      {popupStatus.errorUpload ? <PopupFailUpload onClose={() => setPopupStatus({ ...popupStatus, errorUpload: false })} failType={'duplication'} /> : null}
      {popupStatus.totalResult ? <PopupResult onClose={() => setPopupStatus({ ...popupStatus, totalResult: false })} /> : null}
    </>
  );
};

export default TestViewList;
