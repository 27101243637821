import React, { useState } from 'react';
import styled from 'styled-components';
import IcoImgDup from 'assets/images/map/ico-img-duplicate.svg';
import IcoImgUpload from 'assets/images/map/ico-img-upload.svg';
import PopupDuplicationPic from 'popup/PopupDuplicationPic';
import Button from 'components/atoms/Button';
import UserSetting from './UserSetting';

function TopBtnGroup(props) {
  const TopBtnGroupStyle = {
    Wrap: styled.div`
      display: flex;
      gap: 8px;
      position: fixed;
      top: 16px;
      right: 18px;
      z-index: 9999;
    `,
  };

  const [openDuplicate, setOpenDuplicate] = useState(false);

  return (
    <TopBtnGroupStyle.Wrap>
      {/* 사진 중복 확인 */}
      <Button text="사진 중복 확인" icon={IcoImgDup} $iconSize={24} $iconSpacing={6} $buttonType="large" padding={16} onClick={() => setOpenDuplicate(true)} />
      {openDuplicate && <PopupDuplicationPic onClose={() => setOpenDuplicate(false)} />}
      {/* 사진 업로드 */}
      <Button text="사진 업로드" icon={IcoImgUpload} $iconSize={24} $iconSpacing={6} $buttonType="large" padding={16} />

      {/* 유저정보 버튼 - 로그아웃 등 */}
      <UserSetting />
    </TopBtnGroupStyle.Wrap>
  );
}

export default TopBtnGroup;
