import React, { ReactNode } from 'react';
import styled from 'styled-components';
import IcoClose from '../assets/images/popup/ico-close.svg';
import IcoClose2 from '../assets/images/popup/ico-close-2.svg';
export const PopupStyle = {
  Dimmed: styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: rgba(0, 0, 0, 0.5);
  `,
  Wrap: styled.div<PopupProps>`
    position: fixed;
    left: 50%;
    top: 50%;
    width: ${(p) => (p.width ? p.width + 'px' : '600px')};
    transform: translate(-50%, -50%);
    z-index: 1000;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.35);
    border-radius: 4px;
    overflow: hidden;
  `,
  Head: styled.div<PopupProps>`
    height: ${(p) => (p.$type === 2 ? '52px' : '56px')};
    background: ${(p) => (p.$type === 2 ? '#024751' : p.$type === 3 ? '#fff' : '#deedc9')};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 28px;
  `,
  Cont: styled.div`
    background: #fff;
    border: 1px solid #d9d9d9;
  `,
  Inner: styled.div<PopupProps>`
    padding: ${(p) => (p.$type === 2 ? '0' : '0 28px')};
  `,
  Title: styled.strong<PopupProps>`
    color: ${(p) => (p.$type === 2 ? '#09D585' : '#3f3f3f')};
    font-size: 16px;
    font-weight: 600;
  `,
  CloseBtn: styled.button<PopupProps>`
    width: 24px;
    height: 24px;
    background: url(${(p) => (p.$type === 2 ? IcoClose2 : IcoClose)}) no-repeat center;
  `,
  Footer: styled.div`
    background: #fff;
    border: 1px solid #d9d9d9;
    border-top: none;
  `,
  FooterInner: styled.div`
    padding: 12px 28px;
  `,
};

export interface PopupProps {
  isDimmed?: boolean;
  title?: string;
  width?: number;
  content?: ReactNode;
  footer?: ReactNode;
  onClose?: () => void;
  $type?: number;
}

const PopupTemplate = ({ isDimmed, title, width, content, footer, onClose, $type }: PopupProps) => {
  return (
    <>
      {isDimmed ? <PopupStyle.Dimmed></PopupStyle.Dimmed> : null}
      <PopupStyle.Wrap width={width}>
        <PopupStyle.Head $type={$type}>
          <PopupStyle.Title $type={$type}>{title}</PopupStyle.Title>
          <PopupStyle.CloseBtn $type={$type} type={'button'} onClick={onClose ? onClose : () => {}}>
            <span className={'hidden'}></span>
          </PopupStyle.CloseBtn>
        </PopupStyle.Head>
        <PopupStyle.Cont>
          <PopupStyle.Inner $type={$type}>{content}</PopupStyle.Inner>
        </PopupStyle.Cont>
        {footer ? (
          <PopupStyle.Footer>
            <PopupStyle.FooterInner>{footer}</PopupStyle.FooterInner>
          </PopupStyle.Footer>
        ) : null}
      </PopupStyle.Wrap>
    </>
  );
};

export default PopupTemplate;
