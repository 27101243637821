import React from 'react';
import styled from 'styled-components';

export const NoDataStyle = {
  Wrap: styled.div<NoDataProps>`
    padding: ${(p) => (p.padding?.length ? p.padding[0] + 'px 0' + p.padding[1] + 'px' : '79px 0')};
  `,
  Icon: styled.div<NoDataProps>`
    width: ${(p) => (p.$iconSize ? p.$iconSize + 'px' : '48px')};
    height: ${(p) => (p.$iconSize ? p.$iconSize + 'px' : '48px')};
    background: url(${(p) => (p.icon ? p.icon : '')}) no-repeat center;
    margin: 0 auto 8px;
  `,
  Text: styled.p<NoDataProps>`
    color: #6f979c;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    text-align: center;
  `,
};

export interface NoDataProps {
  padding?: number[];
  fontSize?: number;
  $iconSize?: number;
  icon?: string;
  text?: string;
}

const NoData = ({ padding, text, fontSize, icon, $iconSize }: NoDataProps) => {
  return (
    <NoDataStyle.Wrap padding={padding}>
      <NoDataStyle.Icon $iconSize={$iconSize} icon={icon}></NoDataStyle.Icon>
      <NoDataStyle.Text fontSize={fontSize} dangerouslySetInnerHTML={{ __html: String(text) }}></NoDataStyle.Text>
    </NoDataStyle.Wrap>
  );
};

export default NoData;
