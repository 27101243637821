import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import OlCore from '../../../ol/OlCore';
import MapType from './MapType';
import IcoCurrent from '../../../assets/images/toolbar/ico-current.svg';
import IcoCurrentActive from '../../../assets/images/toolbar/ico-current-hover.svg';

const RangeSliderStyleDefault = {
  Contain: styled.div`
    display: flex;
    flex-direction: column;
    z-index: 100;
    border: 1px solid #ccc;
    box-sizing: border-box;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    background: #fff;
  `,
  Btn: styled.button`
    position: relative;
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    &:before,
    &:after {
      display: block;
      content: '';
      width: 12px;
      height: 2px;
      background: #6d6d6d;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    &:first-child {
      border-bottom: 1px solid #ccc;
      border-radius: 4px 4px 0 0;
      &:before {
        width: 2px;
        height: 12px;
      }
    }
    &:last-child {
      border-top: 1px solid #ccc;
      border-radius: 0 0 4px 4px;
      &:after {
        display: none;
      }
    }
  `,
  Wrap: styled.div`
    position: relative;
    width: 40px;
    height: 118px;
    background: #fff;
    padding: 10px 0;
  `,
  LabelList: styled.ul`
    position: absolute;
    left: 70%;
    top: 0;
    display: flex;
    flex-direction: column;
    height: calc(100% - 3px);
    justify-content: space-between;
    align-items: flex-start;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
  `,
  LabelItem: styled.li`
    position: relative;
    font-size: 10px;
    background: #fff;
    width: max-content;
    height: 20px;
    line-height: 20px;
    padding: 0 6px;
    text-align: center;
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: 0 0 3px 5px rgba(0, 0, 0, 0.1);
    border: 1px solid #8bc3c3;
    color: #01555e;
    font-family: 'Pretendard';
    font-weight: 400;
    &:before {
      content: '';
      position: absolute;
      z-index: 2;
      right: calc(100% - 3px);
      top: 50%;
      transform: translateY(-50%) rotate(-45deg);
      border: 3px solid transparent;
      border-left: 3px solid #fff;
      border-top: 3px solid #fff;
    }
    &:after {
      content: '';
      position: absolute;
      right: calc(100% - 4px);
      top: 50%;
      transform: translateY(-50%) rotate(-45deg);
      border: 4px solid transparent;
      border-left: 4px solid #8bc3c3;
      border-top: 4px solid #8bc3c3;
    }
  `,
  InputWrap: styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    background: #fff;
    width: 12px;
    height: calc(100% - 10px);
    transform: translate(-50%, -50%);
  `,
  Input: styled.input`
    position: absolute;
    left: 50%;
    top: calc(100% - 5px);
    transform: rotate(270deg);
    transform-origin: left center;
    -webkit-appearance: none;
    width: 122px;
    height: 4px;
    background: #ccc;
    outline: none;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 6px;
      height: 24px;
      background: #fff;
      box-sizing: border-box;
      border: 1px solid #272727;
      cursor: pointer;
    }
  `,
  TickWrap: styled.ul<{ value: string }>`
      position:relative;display:flex;flex-direction:column;align-items:center;justify-content: space-between;height:100%;overflow: hidden;
      &:before{position:absolute;left:50%;bottom:-2px;content:'';width:4px;z-index:10;background: #09D585;transform: translateX(-50%);
          height:${(p) => (p.value ? p.value + '%' : 0)};
    `,
  Tick: styled.li`
    width: 24px;
    box-sizing: border-box;
    border-bottom: 1px solid #8d8d8d;
    flex: 1;
    &:nth-child(2n-1) {
      border-bottom: 1px solid #d9d9d9;
    }
    &:first-child {
      border-top: 1px solid #8d8d8d;
    }
    //&:nth-child(1),&:nth-child(2),&:nth-child(4){border-bottom:1px solid #aaa;width:24px;}
  `,
};

const RangeSliderStyle = {
  ...RangeSliderStyleDefault,
  Contain: styled(RangeSliderStyleDefault.Contain)`
    &:hover {
      ${RangeSliderStyleDefault.LabelList} {
        visibility: visible;
        opacity: 1;
        left: calc(100% + 7px);
      }
    }
  `,
};
const CurrentButton = styled.button`
  width: 42px;
  height: 42px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #fff url(${IcoCurrent}) no-repeat center;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
  margin-top: 20px;
  &:hover {
    background-image: url(${IcoCurrentActive});
  }
`;

const RangeSlider = ({ mapInstance }: { mapInstance?: OlCore }) => {
  const [value, setValue] = useState(0);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    mapInstance?.getMapInstance().getView().setZoom(Number(e.target.value));
    setValue(Number(e.target.value));
  };

  //+, - 버튼 클릭
  const onPlusMinus = (type: string) => {
    if (type === 'plus' && value < 18) {
      mapInstance
        ?.getMapInstance()
        .getView()
        .setZoom(value + 1);
      setValue(Number(value + 1));
    } else if (type === 'minus' && value > 8) {
      mapInstance
        ?.getMapInstance()
        .getView()
        .setZoom(value - 1);
      setValue(Number(value - 1));
    }
  };

  useEffect(() => {
    mapInstance?.onMoveEnd(() => setValue(Number(mapInstance?.getMapInstance().getView().getZoom())));
  }, [mapInstance]);

  return (
    <div
      style={{
        position: 'absolute',
        left: 16,
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 10,
      }}
    >
      <MapType mapInstance={mapInstance} />
      <RangeSliderStyle.Contain>
        <RangeSliderStyle.Btn type={'button'} onClick={() => onPlusMinus('plus')}></RangeSliderStyle.Btn>
        <RangeSliderStyle.Wrap>
          <RangeSliderStyle.LabelList>
            <RangeSliderStyle.LabelItem>건물</RangeSliderStyle.LabelItem>
            <RangeSliderStyle.LabelItem>동</RangeSliderStyle.LabelItem>
            {/*<RangeSliderStyle.LabelItem style={{ marginTop: 28 }}>공장별</RangeSliderStyle.LabelItem>*/}
            <RangeSliderStyle.LabelItem>읍면</RangeSliderStyle.LabelItem>
            <RangeSliderStyle.LabelItem>시군구</RangeSliderStyle.LabelItem>
            <RangeSliderStyle.LabelItem>시/도</RangeSliderStyle.LabelItem>
          </RangeSliderStyle.LabelList>
          <RangeSliderStyle.TickWrap value={`${(Number(value) - 8) * 10 - 1}`}>
            <RangeSliderStyle.Tick></RangeSliderStyle.Tick>
            <RangeSliderStyle.Tick></RangeSliderStyle.Tick>
            <RangeSliderStyle.Tick></RangeSliderStyle.Tick>
            <RangeSliderStyle.Tick></RangeSliderStyle.Tick>
            <RangeSliderStyle.Tick></RangeSliderStyle.Tick>
            <RangeSliderStyle.Tick></RangeSliderStyle.Tick>
            <RangeSliderStyle.Tick></RangeSliderStyle.Tick>
            <RangeSliderStyle.Tick></RangeSliderStyle.Tick>
            <RangeSliderStyle.Tick></RangeSliderStyle.Tick>
          </RangeSliderStyle.TickWrap>
          <RangeSliderStyle.InputWrap>
            <RangeSliderStyle.Input type={'range'} min={8} max={18} step={0.1} onChange={onChange} value={value} />
          </RangeSliderStyle.InputWrap>
        </RangeSliderStyle.Wrap>
        <RangeSliderStyle.Btn type={'button'} onClick={() => onPlusMinus('minus')}></RangeSliderStyle.Btn>
      </RangeSliderStyle.Contain>
      <CurrentButton type={'button'}>
        <span className={'hidden'}>동내 사업장으로 이동</span>
      </CurrentButton>
    </div>
  );
};

export default RangeSlider;
