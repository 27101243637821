import Map from 'pages/Map';
import React from 'react';

function Container(props) {
  return (
    <div style={{ width: '100%', height: '100vh' }}>
      {/* <Header /> */}
      <Map />
    </div>
  );
}

export default Container;
