import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import IcoTotal from 'assets/images/cropInfoBox/ico-total.svg';
import Pagination from 'components/atom/Pagination';
import Checkbox from 'components/atoms/Checkbox';
import useOnChange from 'hooks/useOnChange';
import Button from '../../atoms/Button';
import IcoInfo from 'assets/images/button/ico-info.svg';
import IcoInfoDisabled from 'assets/images/button/ico-info-disabled.svg';
import IcoPic from 'assets/images/button/ico-pic.svg';
import IcoPicCompletion from 'assets/images/button/ico-pic-completion.svg';
import LabelValue from 'components/atoms/LabelValue';
import Badge, { BadgeArea } from 'components/atoms/Badge';
import ImageW from 'assets/images/image-w.png';
import ImageZ from 'assets/images/image-z.png';
import usePopupToggle from '../../../hooks/custom/usePopupToggle';
import PopupLotInfo from '../../../popup/PopupLotInfo';
import Input from 'components/atoms/Input';

import { CropInfoSt } from './_style';
import PopupLargeImage from 'popup/PopupLargeImage';

function CropInfoBox({ close }) {
  const [selected, setSelected] = useState(['a-1']);
  const [popupState, setPopupState] = useState({
    lotInfo: false,
  });
  const onTogglePopup = usePopupToggle(popupState, setPopupState);

  //카드 더미데이터
  const [cardData, setCardData] = useState([
    { farmId: 457703503254370, imageW: ImageW, imageZ: ImageZ, address: '제주도 제주시 이도이동 1231-342', share: true, remaining: true, success: true, active: false },
    { farmId: 457703503254371, imageW: ImageW, imageZ: ImageZ, address: '제주도 제주시 이도일동 1231-342', share: false, remaining: false, success: false, active: false },
    { farmId: 457703503254372, imageW: ImageW, imageZ: ImageZ, address: '제주도 제주시 이도일동 1231-342', share: true, remaining: true, success: false, active: false },
    { farmId: 457703503254373, imageW: ImageW, imageZ: ImageZ, address: '제주도 제주시 이도일동 1231-342', share: true, remaining: false, success: false, active: false },
    { farmId: 457703503254374, imageW: ImageW, imageZ: ImageZ, address: '제주도 제주시 이도일동 1231-342', share: false, remaining: true, success: false, active: false },
    { farmId: 457703503254375, imageW: ImageW, imageZ: ImageZ, address: '제주도 제주시 이도일동 1231-342', share: false, remaining: false, success: false, active: false },
    { farmId: 457703503254376, imageW: ImageW, imageZ: ImageZ, address: '제주도 제주시 이도일동 1231-342', share: false, remaining: false, success: false, active: false },
  ]);

  //카드 전체체크여부 변수
  const [allChecked, setAllChecked] = useState(false);

  //카드 체크 함수
  const onClickCard = (farmId: number) => {
    setCardData((prev) => {
      return prev.map((i) => {
        return {
          ...i,
          active: i.farmId === farmId ? !i.active : i.active,
        };
      });
    });
  };

  //카드 전체선택
  const onClickAllCard = () => {
    setCardData((prev) => {
      if (prev.filter(({ active }) => active).length === prev.length) {
        return prev.map((i) => {
          return {
            ...i,
            active: false,
          };
        });
      } else {
        return prev.map((i) => {
          return {
            ...i,
            active: true,
          };
        });
      }
    });
  };

  //전체선택 여부 트리거
  useEffect(() => {
    if (cardData.length !== 0) {
      if (cardData.length === cardData.filter(({ active }) => active).length) {
        setAllChecked(true);
      } else {
        setAllChecked(false);
      }
    }
  }, [cardData]);

  const initFormField = {
    Allfield: false,
    field1: false,
    field2: false,
    field3: false,
    field4: false,
  };
  const [formField, setFormField, onChangeField] = useOnChange(initFormField);
  console.log(formField);

  // 모두 체크될때 자동으로 [전체] 체크 처리 또는 해제
  useEffect(() => {
    // 4개 모두 true인지 확인(every)
    const isAllTrue = Object.entries(formField)
      .filter(([key]) => key !== 'Allfield')
      .every(([key, value]) => value);

    if (isAllTrue && !formField['Allfield']) {
      setFormField((prev) => ({ ...prev, Allfield: true }));
    }
    if (!isAllTrue && formField['Allfield']) {
      setFormField((prev) => ({ ...prev, Allfield: false }));
    }
  }, [formField, setFormField]);

  // 필드 - [전체] 선택시
  const onChangeAllField = (e) => {
    const { name, checked } = e.target;

    if (name === 'Allfield') {
      setFormField((prev) => {
        let newform = {};
        Object.keys(prev).map((key) => (newform[key] = checked));
        return newform;
      });
    }
  };

  // 선택된 카드 개수
  const selectedCardLength = cardData?.filter(({ active }) => active)?.length;

  return (
    <>
      <CropInfoSt.Wrap className={!cardData.filter(({ active }) => active).length ? 'hide' : undefined}>
        <CropInfoSt.Header>
          <p className="title">작물 정보</p>
          <span className="closeBtn" onClick={close}></span>
        </CropInfoSt.Header>
        <CropInfoSt.ContainerWrap>
          <CropInfoSt.Container className={!cardData.filter(({ active }) => active).length ? 'hide' : undefined}>
            <CropInfoSt.TypeCheckWrap>
              <ul>
                <li>
                  <Checkbox text="전체" id="Allfield" name="Allfield" onChange={onChangeAllField} checked={formField?.Allfield} />
                </li>
                <li>
                  <Checkbox text="논" id="field1" name="field1" onChange={onChangeField} checked={formField?.field1} />
                </li>
                <li>
                  <Checkbox text="밭" id="field2" name="field2" onChange={onChangeField} checked={formField?.field2} />
                </li>
                <li>
                  <Checkbox text="과수" id="field3" name="field3" onChange={onChangeField} checked={formField?.field3} />
                </li>
                <li>
                  <Checkbox text="시설" id="field4" name="field4" onChange={onChangeField} checked={formField?.field4} />
                </li>
              </ul>
            </CropInfoSt.TypeCheckWrap>

            <CropInfoSt.CropCheckWrap>
              <ul>
                <li>양파</li>
                <li>마늘</li>
              </ul>
            </CropInfoSt.CropCheckWrap>

            <CropInfoSt.ContentWrap>
              <CropInfoSt.Content>
                {/* 전체 선택 체크박스 */}
                <div className="selectAll">
                  <Checkbox text="전체 선택" id="AllCard" name="all" onChange={onClickAllCard} checked={allChecked} />

                  <div className="selectWrap">
                    <p>선택</p>
                    <span className="num">{selectedCardLength}</span>
                  </div>
                </div>
                {/* 필터 버튼 및 페이지 표기 */}
                <div className="filterWrap">
                  <div className="btnWrap">
                    <button className="active">점유율</button>
                    <button className="active">잔여량</button>
                  </div>
                  <div className="totalWrap">
                    <span>조회 결과 / </span>
                    <span className="total">{'150'}건</span>
                  </div>
                </div>
                {/* 카드 리스트 영역 */}
                <CropInfoSt.CardWrap className="cScroll">
                  {cardData?.map((item) => {
                    return (
                      <CropInfoSt.Card className={item.active ? 'active' : undefined} key={item.farmId}>
                        <CropInfoSt.CardHead>
                          <Checkbox text={item.farmId} fontSize={16} id={`check_${item.farmId}`} $sameColor={true} onChange={() => onClickCard(item.farmId)} checked={item.active} />
                          <Button $buttonType={'small'} text={'상세'} $iconSize={16} icon={IcoInfo} $iconDisabled={IcoInfoDisabled} $iconSpacing={2} onClick={() => onTogglePopup('lotInfo')} />
                        </CropInfoSt.CardHead>
                        <CropInfoSt.CardCont onClick={() => onClickCard(item.farmId)}>
                          <CropInfoSt.CardImageArea>
                            <img src={item.imageW} alt={item.farmId + '_근경'} />
                            <img src={item.imageZ} alt={item.farmId + '_원경'} />
                          </CropInfoSt.CardImageArea>
                        </CropInfoSt.CardCont>
                        <CropInfoSt.CardFoot onClick={() => onClickCard(item.farmId)}>
                          <CropInfoSt.CardLabelValueArea>
                            <LabelValue $ellipsis={true} label={'법정동 주소'} value={item.address} />
                            <LabelValue
                              label={'판독'}
                              value={
                                <BadgeArea>
                                  {item.success ? <Badge type={'orange'} text={'작업완료'} /> : null}
                                  {!item.success && item.share ? <Badge type={'sky'} text={'점유율'} /> : null}
                                  {!item.success && item.remaining ? <Badge type={'green'} text={'잔여량'} /> : null}
                                  {!item.success && !item.remaining && !item.share ? <Badge text={'-'} /> : null}
                                </BadgeArea>
                              }
                            />
                          </CropInfoSt.CardLabelValueArea>
                        </CropInfoSt.CardFoot>
                      </CropInfoSt.Card>
                    );
                  })}
                </CropInfoSt.CardWrap>
              </CropInfoSt.Content>
              {/* 페이지네이션 영역 */}
              <div className="pageListWrap">
                <Pagination />
              </div>
            </CropInfoSt.ContentWrap>

            {/* 선택된 카드가 있을때 오픈 */}
            {cardData.filter(({ active }) => active).length && (
              <>
                <ActiveArea hide={!cardData.filter(({ active }) => active).length} cardData={cardData} />
                <DataEnterArea hide={!cardData.filter(({ active }) => active).length} />
              </>
            )}
          </CropInfoSt.Container>
        </CropInfoSt.ContainerWrap>
      </CropInfoSt.Wrap>
      {popupState.lotInfo ? <PopupLotInfo onClose={() => onTogglePopup('lotInfo')} /> : null}
    </>
  );
}

export default CropInfoBox;

export const ActiveAreaSt = {
  Wrap: styled.div`
    width: calc(100% - 485px - 304px); // 고정영역을 제외
    display: flex;
    flex-direction: column;
    border-left: 1px solid #e9e7e7;
    transition: 0.4s;
    &.hide {
      width: 0;
      overflow: hidden;
      visibility: hidden;
    }
  `,
  PicArea: styled.div`
    width: 100%;
    padding: 10px 12px;
    box-sizing: border-box;
    border-bottom: 1px solid #e9e7e7;
    display: flex;
    flex-direction: column;
    .picWrap {
      width: 100%;

      overflow-y: hidden;
      height: 372px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 6px;
    }
  `,
  PicCard: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 470px;
    height: 180px;
    flex-shrink: 0;
    background-color: #fff;
    border-radius: 12px;
    padding: 2px;
    box-sizing: border-box;
    border: 1px solid #e9e7e7;
    &.single {
      width: 100%;
      height: 100%;
      strong {
        width: auto;
      }
    }
    &.active {
      background: #00b074;
      > div {
        background: #d1f1e6;
      }
    }
    &.disabled {
      position: relative;
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        /* z-index: ; */
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.5);
      }
    }
  `,
  PicCardInner: styled.div`
    background: #fff;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    border-radius: 9px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  PicBox: styled.div`
    position: relative;
    flex: 1;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid #e9e7e7;
    border-radius: 8px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
    &:not(:last-child) {
      margin-right: 2px;
    }
    button {
      position: absolute;
      right: 8px;
      bottom: 8px;
      z-index: 9;
    }
  `,
  PicTitle: styled.strong`
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 12px;
    width: calc(100% - 24px);
    height: 32px;
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.6);
    color: #00b074;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    line-height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
  MapArea: styled.div`
    flex: 1;
    width: 100%;
    padding: 12px 17px;
    box-sizing: border-box;
    .mapContainer {
      width: 100%;
      height: 100%;
      background-color: #c3c3c3;
      border-radius: 8px;
    }
  `,
};

// 선택된 항목 있을때 상세 이미지 & 지도 영역
function ActiveArea({ hide, cardData }) {
  const [selected, setSelected] = useState(null); //복수사진 선택된 항목
  //더미데이터
  const [imageData, setImageData] = useState([
    { id: 1, imageW: ImageW, imageZ: ImageZ, active: false, completion: false, titleW: '002341123-240823-001-1W.jpg', titleZ: '002341123-240823-001-1W.jpg' },
    //  { id: 2, imageW: ImageW, imageZ: ImageZ, active: false, completion: false, titleW: '002341123-240823-001-1W.jpg', titleZ: '002341123-240823-001-1W.jpg' },
    //  { id: 3, imageW: ImageW, imageZ: ImageZ, active: false, completion: false, titleW: '002341123-240823-001-1W.jpg', titleZ: '002341123-240823-001-1W.jpg' },
    //  { id: 4, imageW: ImageW, imageZ: ImageZ, active: false, completion: false, titleW: '002341123-240823-001-1W.jpg', titleZ: '002341123-240823-001-1W.jpg' },
    //  { id: 5, imageW: ImageW, imageZ: ImageZ, active: false, completion: false, titleW: '002341123-240823-001-1W.jpg', titleZ: '002341123-240823-001-1W.jpg' },
  ]);

  // 선택된 카드 데이터 맵핑해서 보여줄 이미지 리스트 데이터 생성
  useEffect(() => {
    console.log(cardData);
    const activeCard = [...cardData].filter(({ active }) => active);

    setImageData([...activeCard]?.map((ele) => ({ ...ele, id: ele.farmId, active: false, completion: false, titleW: '원경', titleZ: '근경' })));
  }, [cardData]);

  // 사진 선택 버튼 클릭
  const selectPic = (id) => {
    setSelected(id);

    setImageData((prev) => {
      return [...prev].map((ele) => (ele.id === id ? { ...ele, active: true, completion: true } : { ...ele, active: false, completion: false }));
    });
  };

  return (
    <>
      {/*<PopupLargeImage />*/}
      <ActiveAreaSt.Wrap className={hide ? 'hide' : undefined}>
        <ActiveAreaSt.PicArea>
          <div className="picWrap cScroll">
            {imageData.length === 1
              ? imageData.map((item) => {
                  return (
                    <ActiveAreaSt.PicCard key={item.id} className={'single'}>
                      <ActiveAreaSt.PicCardInner>
                        <ActiveAreaSt.PicBox>
                          <ActiveAreaSt.PicTitle>{item.titleW}</ActiveAreaSt.PicTitle>
                          <img src={item.imageW} alt={item.titleW + '_근경'} />
                        </ActiveAreaSt.PicBox>
                        <ActiveAreaSt.PicBox>
                          <ActiveAreaSt.PicTitle>{item.titleZ}</ActiveAreaSt.PicTitle>
                          <img src={item.imageZ} alt={item.titleZ + '_원경'} />
                        </ActiveAreaSt.PicBox>
                      </ActiveAreaSt.PicCardInner>
                    </ActiveAreaSt.PicCard>
                  );
                })
              : imageData.map((item) => {
                  return (
                    <ActiveAreaSt.PicCard key={item.id} className={item.active ? 'active' : selected ? 'disabled' : ''}>
                      <ActiveAreaSt.PicCardInner>
                        <ActiveAreaSt.PicBox>
                          <ActiveAreaSt.PicTitle>{item.titleW}</ActiveAreaSt.PicTitle>
                          <img src={item.imageW} alt={item.titleW + '_근경'} />
                        </ActiveAreaSt.PicBox>
                        <ActiveAreaSt.PicBox>
                          <ActiveAreaSt.PicTitle>{item.titleZ}</ActiveAreaSt.PicTitle>
                          <img src={item.imageZ} alt={item.titleZ + '_원경'} />
                          {/* 단일 필지 선택시, 복수 사진일때 버튼 */}
                          <Button $buttonType={'medium'} text={'사진 선택'} $iconSize={24} icon={IcoPic} $iconSpacing={6} $isCompletion={item.completion} $iconCompletion={IcoPicCompletion} onClick={() => selectPic(item?.id)} />
                        </ActiveAreaSt.PicBox>
                      </ActiveAreaSt.PicCardInner>
                    </ActiveAreaSt.PicCard>
                  );
                })}
          </div>
        </ActiveAreaSt.PicArea>
        <ActiveAreaSt.MapArea>
          <div className="mapContainer"></div>
        </ActiveAreaSt.MapArea>
      </ActiveAreaSt.Wrap>
    </>
  );
}

const DataEnterAreaSt = {
  Wrap: styled.div`
    /* flex: 1; */
    width: 304px;
    /* height: 100%; */
    border-left: 1px solid #e9e7e7;
    background-color: #ffffff;
    box-shadow: -5px 0px 5px 0px rgba(0, 0, 0, 0.08);
    transition: 0.4s;
    &.hide {
      width: 0;
      overflow: hidden;
      visibility: hidden;
    }
  `,
  TabBtnWrap: styled.div`
    display: flex;
    .tabBtn {
      width: 152px;
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #e9e7e7;
      box-sizing: border-box;
      background: #f3f5f4;
      color: #7e8888cc;
      font-weight: 600;
      &.active {
        position: relative;
        background-color: #fff;
        color: #001a21;
        &::after {
          content: '';
          position: absolute;
          bottom: 0px;
          width: 100%;
          height: 3px;
          border-radius: 2px 2px 0px 0px;
          background: #00b074;
        }
      }
    }
  `,
  ReadGrdTypeWrap: styled.div`
    ul {
      li.radioItem {
        height: 48px;
        display: flex;
        align-items: center;
        padding-left: 16px;
        font-weight: 500;
        &:not(:last-child) {
          border-bottom: 1px solid #e9e7e7;
        }
      }
    }
  `,
  FormWrap: styled.div`
    padding: 10px 16px 16px 42px;
    border-top: 1px solid #e9e7e7;
    background-color: #f0f5f3;
    ul {
      display: flex;
      flex-direction: column;
      gap: 8px;
      li.formItem {
        .labelInput {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .label {
            color: #00b074;
            font-weight: 500;
          }
        }
        .value {
          margin-top: 4px;
          margin-bottom: 8px;
          height: 17px;
          text-align: right;
          color: #6f979c;
          font-weight: 500;
        }
      }
    }
  `,
  SaveWrap: styled.div`
    width: 100%;
    padding: 14px 12px 0 12px;
    box-sizing: border-box;
    border-top: 2px solid #00b074;
    display: flex;
    flex-direction: column;
    .totalWrap {
      margin-bottom: 16.5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .titleWrap {
        display: flex;
        align-items: center;
        gap: 8px;
        .ico {
          display: block;
          width: 18px;
          height: 18px;
          background: url(${IcoTotal}) no-repeat center;
        }
        .title {
          color: #024751;
          font-weight: 500;
        }
      }
      .valueWrap {
        display: flex;
        align-items: center;
        gap: 8px;
        .value {
          color: #024751;
          font-size: 16px;
          font-weight: 600;
        }
        .unit {
          color: #7e8888;
        }
      }
    }
    .lastUpdate {
      line-height: 14px;
      color: #00b074;
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 16px;
      text-align: right;
      text-decoration-line: underline;
    }
  `,
};

const radioOption = ['비경지', '휴경지', '시설', '경작지'];

// 선택된 항목 있을때 데이터 입력하는 영역
function DataEnterArea({ hide }) {
  const [tabNum, setTabNum] = useState(1);
  const [radioValue, setRadioValue] = useState(''); // 필지 타입 판독하기

  const [totalSum, setTotalSum] = useState(0); // 점유율 총합
  const [totalSum2, setTotalSum2] = useState(0); // 잔여량 총합
  const [form, setForm, onChange] = useOnChange({}); // [점유율]
  const [form2, setForm2, onChange2] = useOnChange({}); // [잔여량]
  console.log(form);

  // [점유율] 전체 총합 구하기
  useEffect(() => {
    if (Object.keys(form)) {
      let sum = 0;
      Object.entries(form).map(([key, value]) => (sum += Number(value)));
      setTotalSum(sum);
    }
  }, [form]);

  // [잔여량] 전체 총합 구하기
  useEffect(() => {
    if (Object.keys(form2)) {
      let sum = 0;
      Object.entries(form2).map(([key, value]) => (sum += Number(value)));
      setTotalSum2(sum);
    }
  }, [form2]);

  const onChangeRadio = (e) => {
    const { type, id, name, value, checked } = e.target;
    //  console.log(name, value);
    if (name === 'grdType') {
      setRadioValue(value);
    }
  };

  return (
    <DataEnterAreaSt.Wrap className={hide ? 'hide' : undefined}>
      <DataEnterAreaSt.TabBtnWrap>
        <button className={`tabBtn ${tabNum === 1 && 'active'}`} onClick={() => setTabNum(1)}>
          점유율
        </button>
        <button className={`tabBtn ${tabNum === 2 && 'active'}`} onClick={() => setTabNum(2)}>
          잔여량
        </button>
      </DataEnterAreaSt.TabBtnWrap>
      <DataEnterAreaSt.ReadGrdTypeWrap>
        {tabNum === 1 && (
          <>
            <form id="myForm" onChange={onChangeRadio}>
              <ul>
                {radioOption.map((value, idx) => (
                  <li key={`radio-${idx}`} className="radioItem">
                    <Checkbox type="radio" name="grdType" id={`grdType-${idx}`} text={value} value={value} style={{ width: '100%', height: '100%' }} defaultChecked={value === radioValue} />
                  </li>
                ))}
              </ul>
            </form>
            {/* 경작지 일때 작물 input 목록 */}
            {radioValue === '경작지' && (
              <DataEnterAreaSt.FormWrap>
                <ul>
                  <li className="formItem">
                    <div className="labelInput">
                      <p className="label">고추</p>
                      <Input width={102} height={36} unit="%" placeholder="입력" name={'고추'} value={form?.고추} onChange={onChange} />
                    </div>
                    <p className="value">전회차: 90 / 당회차: {80}</p>
                  </li>
                  <li className="formItem">
                    <div className="labelInput">
                      <p className="label">귀리</p>
                      <Input width={102} height={36} unit="%" placeholder="입력" name={'귀리'} value={form?.귀리} onChange={onChange} />
                    </div>
                  </li>
                </ul>
              </DataEnterAreaSt.FormWrap>
            )}
            <DataEnterAreaSt.SaveWrap>
              {radioValue === '경작지' && (
                <div className="totalWrap">
                  <div className="titleWrap">
                    <span className="ico"></span>
                    <p className="title">전체 총합</p>
                  </div>
                  <div className="valueWrap">
                    <p className="value">{totalSum}</p>
                    <span className="unit">{'%'}</span>
                  </div>
                </div>
              )}
              <p className="lastUpdate">최종변경 : 2024년 10월 1일, 08:00 AM</p>
              <Button width={280} text="저장" disabled={!radioValue} />
            </DataEnterAreaSt.SaveWrap>
          </>
        )}
        {tabNum === 2 && (
          <>
            <ul>
              <li className="radioItem">경작지</li>
            </ul>
            <DataEnterAreaSt.FormWrap>
              <ul>
                <li className="formItem">
                  <div className="labelInput">
                    <p className="label">고추</p>
                    <Input width={102} height={36} unit="%" placeholder="입력" name={'고추'} value={form2?.고추} onChange={onChange2} />
                  </div>
                </li>
                <li className="formItem">
                  <div className="labelInput">
                    <p className="label">귀리</p>
                    <Input width={102} height={36} unit="%" placeholder="입력" name={'귀리'} value={form2?.귀리} onChange={onChange2} />
                  </div>
                </li>
              </ul>
            </DataEnterAreaSt.FormWrap>
            <DataEnterAreaSt.SaveWrap>
              <div className="totalWrap">
                <div className="titleWrap">
                  <span className="ico"></span>
                  <p className="title">전체 총합</p>
                </div>
                <div className="valueWrap">
                  <p className="value">{totalSum2}</p>
                  <span className="unit">{'%'}</span>
                </div>
              </div>
              <p className="lastUpdate">최종변경 : 2024년 10월 1일, 08:00 AM</p>
              <Button width={280} text="저장" disabled={!radioValue} />
            </DataEnterAreaSt.SaveWrap>
          </>
        )}
      </DataEnterAreaSt.ReadGrdTypeWrap>
    </DataEnterAreaSt.Wrap>
  );
}
