import React, { useState } from 'react';
import PopupTemplate from './PopupTemplate';
import styled from 'styled-components';
import Button from '../components/atoms/Button';
import IcoInfo from '../assets/images/button/ico-info.svg';
import IcoInfoDisabled from '../assets/images/button/ico-info-disabled.svg';
import Pagination from '../components/atom/Pagination';
import IcoPic from '../assets/images/button/ico-pic.svg';
import IcoPicCompletion from '../assets/images/button/ico-pic-completion.svg';
import { ActiveAreaSt } from '../components/templates/InfoBox/CropInfoBox';
import ImageW from '../assets/images/image-w.png';
import ImageZ from '../assets/images/image-z.png';
import IcoPass from '../assets/images/button/ico-pass.svg';
import IcoNext from '../assets/images/button/ico-next.svg';
import IcoNoData from '../assets/images/noData/ico-no-image.svg';
import NoData from '../components/atoms/NoData';

const Content = {
  Wrap: styled.div`
    display: flex;
    height: 744px;
  `,
  LeftCol: styled.div`
    width: 304px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border-right: 1px solid #d9d9d9;
    background: #f0f0f0;
  `,
  RightCol: styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #f0f0f0;
  `,
  Head: styled.div`
    display: flex;
    align-items: center;
    height: 40px;
    background: #ffffff;
    box-sizing: border-box;
    border-bottom: 1px solid #d9d9d9;
  `,
  Cont: styled.div`
    height: calc(100% - 104px);
    box-sizing: border-box;
  `,
  Foot: styled.div`
    display: flex;
    align-items: center;
    height: 64px;
    box-sizing: border-box;
    border-top: 1px solid #d9d9d9;
  `,
  ButtonArea: styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    padding: 0 16px;
  `,
  Scroll: styled.div`
    height: calc(100% - 16px);
    padding: 8px 16px;
  `,
  Tip: styled.p`
    padding-left: 16px;
    color: #00b074;
    font-size: 14px;
    font-weight: 500;
  `,
  Count: styled.div`
    display: flex;
    align-items: center;
    color: #00b074;
    font-size: 14px;
    font-weight: 500;
    span {
      margin-left: 4px;
      color: #024751;
      font-weight: 600;
    }
  `,
  List: styled.ul`
    height: calc(100% - 16px);
    padding: 8px 12px;
  `,
  Item: styled.li`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #e9e7e7;
    padding: 0 16px;
    background: #fff;
    &:not(:last-child) {
      margin-bottom: 6px;
    }
    &.completion {
      background: #ceefd4;
      border-color: #b0dfb9;
      span {
        background: #00b074;
      }
    }
    &.active {
      border: 2px solid #00b074;
      padding: 0 15px;
    }
    strong {
      color: #024751;
      font-size: 16px;
      font-weight: 500;
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      text-align: center;
      color: #fff;
      font-size: 12px;
      font-weight: 600;
      margin-left: 6px;
      background: #024751;
      margin-right: auto;
    }
  `,
};

const PopupDuplicationPic = ({ onClose }) => {
  const [selected, setSelected] = useState(null); //복수사진 선택된 항목

  //카드 더미데이터
  const [listData, setListData] = useState<any>([
    { farmId: 457703503254370, count: 1, completion: true, active: false },
    { farmId: 457703503254371, count: 1, completion: true, active: false },
    { farmId: 457703503254372, count: 1, completion: true, active: false },
    { farmId: 457703503254373, count: 1, completion: false, active: true },
    { farmId: 457703503254374, count: 1, completion: false, active: false },
    { farmId: 457703503254375, count: 1, completion: false, active: false },
    { farmId: 457703503254376, count: 1, completion: false, active: false },
  ]);

  const [imageData, setImageData] = useState([
    { id: 1, imageW: ImageW, imageZ: ImageZ, active: false, completion: false, titleW: '002341123-240823-001-1W.jpg', titleZ: '002341123-240823-001-1W.jpg' },
    { id: 2, imageW: ImageW, imageZ: ImageZ, active: false, completion: false, titleW: '002341123-240823-001-1W.jpg', titleZ: '002341123-240823-001-1W.jpg' },
    { id: 3, imageW: ImageW, imageZ: ImageZ, active: false, completion: false, titleW: '002341123-240823-001-1W.jpg', titleZ: '002341123-240823-001-1W.jpg' },
    { id: 4, imageW: ImageW, imageZ: ImageZ, active: false, completion: false, titleW: '002341123-240823-001-1W.jpg', titleZ: '002341123-240823-001-1W.jpg' },
    { id: 5, imageW: ImageW, imageZ: ImageZ, active: false, completion: false, titleW: '002341123-240823-001-1W.jpg', titleZ: '002341123-240823-001-1W.jpg' },
  ]);

  // 사진 선택 버튼 클릭
  const selectPic = (id) => {
    setSelected(id);

    setImageData((prev) => {
      return [...prev].map((ele) => (ele.id === id ? { ...ele, active: true, completion: true } : { ...ele, active: false, completion: false }));
    });
  };
  return (
    <PopupTemplate
      title={'사진 중복 확인'}
      width={807}
      $type={2}
      isDimmed={true}
      onClose={onClose}
      content={
        <Content.Wrap>
          <Content.LeftCol>
            <Content.Head style={{ justifyContent: 'center', background: listData.length ? '#fff' : 'transparent' }}>
              <Content.Count>
                중복 사진 필지 / <span>{listData.length}건</span>
              </Content.Count>
            </Content.Head>
            <Content.Cont>
              {listData.length ? (
                <Content.List>
                  {listData.map((item) => {
                    return (
                      <Content.Item className={item.completion ? 'completion' : item.active ? 'active' : ''} key={item.farmId}>
                        <strong>{item.farmId}</strong>
                        <span>{item.count}</span>
                        <Button $buttonType={'small'} text={'상세'} $iconSize={16} icon={IcoInfo} $iconDisabled={IcoInfoDisabled} $iconSpacing={2} onClick={() => {}} />
                      </Content.Item>
                    );
                  })}
                </Content.List>
              ) : null}
            </Content.Cont>
            {listData.length ? (
              <Content.Foot style={{ justifyContent: 'center' }}>
                <Pagination />
              </Content.Foot>
            ) : null}
          </Content.LeftCol>
          <Content.RightCol>
            {listData.length ? (
              <Content.Head>
                <Content.Tip>* 필지 하나 당 하나의 사진만 선택해서 등록하세요.</Content.Tip>
              </Content.Head>
            ) : null}
            <Content.Cont>
              {listData.length ? (
                <Content.Scroll className="cScroll">
                  {imageData.map((item) => {
                    return (
                      <ActiveAreaSt.PicCard key={item.id} className={item.active ? 'active' : selected ? 'disabled' : ''} style={{ width: 'auto' }}>
                        <ActiveAreaSt.PicCardInner>
                          <ActiveAreaSt.PicBox>
                            <ActiveAreaSt.PicTitle>{item.titleW}</ActiveAreaSt.PicTitle>
                            <img src={item.imageW} alt={item.titleW + '_근경'} />
                          </ActiveAreaSt.PicBox>
                          <ActiveAreaSt.PicBox>
                            <ActiveAreaSt.PicTitle>{item.titleZ}</ActiveAreaSt.PicTitle>
                            <img src={item.imageZ} alt={item.titleZ + '_원경'} />
                            {/* 단일 필지 선택시, 복수 사진일때 버튼 */}
                            <Button $buttonType={'medium'} text={'사진 선택'} $iconSize={24} icon={IcoPic} $iconSpacing={6} $isCompletion={item.completion} $iconCompletion={IcoPicCompletion} onClick={() => selectPic(item?.id)} />
                          </ActiveAreaSt.PicBox>
                        </ActiveAreaSt.PicCardInner>
                      </ActiveAreaSt.PicCard>
                    );
                  })}
                </Content.Scroll>
              ) : (
                <NoData text={'중복되는 사진을<br/>모두 삭제 처리하였습니다.'} icon={IcoNoData} $iconSize={48} fontSize={16} padding={[79, 79]} />
              )}
            </Content.Cont>

            {listData.length ? (
              <Content.Foot>
                <Content.ButtonArea>
                  <Button $buttonType={'large'} text={'건너뛰기'} height={36} icon={IcoPass} $icoPosition={'right'} $iconSize={24} $iconSpacing={6} padding={12} fontSize={14} style={{ marginRight: 8 }} />
                  <Button text={'다음사진'} height={36} icon={IcoNext} $icoPosition={'right'} $iconSize={24} $iconSpacing={6} padding={12} fontSize={14} />
                </Content.ButtonArea>
              </Content.Foot>
            ) : null}
          </Content.RightCol>
        </Content.Wrap>
      }
    />
  );
};

export default PopupDuplicationPic;
