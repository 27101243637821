import React, { useEffect, useState } from 'react';
import './App.scss';
import Container from 'components/templates/Container/Container';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Login from 'pages/Login';
import NotFound from 'pages/NotFound';
import PopupResult from './popup/PopupResult';
import Confirm from './components/templates/Confirm';
import { useRecoilState } from 'recoil';
import { confirmState, loadingState } from './store/Atoms';
import Loading from './components/atoms/Loading';

function App() {
  const [confirmInfo] = useRecoilState(confirmState);
  const [loadingStatus] = useRecoilState(loadingState);
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Container />,
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: '*', // 모든 다른 경로는 404 페이지로 처리
      element: <NotFound />,
    },
  ]);

  return (
    <>
      {confirmInfo.show ? <Confirm /> : null}
      {loadingStatus.show ? <Loading /> : null}
      <RouterProvider router={router} />
      {/* <Container /> */}
    </>
  );
}

export default App;
