import React, { useMemo } from 'react';
import { PopupSiteInfoStyle } from '../Map/PopupSiteInfo';
import { InfoBoxSt } from './InfoBox';
import Table from '../Table';
import { Feature } from 'ol';
import { Stroke } from 'ol/style.js';
import { transform } from 'ol/proj';

const PointInfoBox = ({ prop, close, addressList }) => {
  const hasFeature = prop instanceof Feature;
  let properties = hasFeature ? prop?.getProperties() : undefined;
  let geometries = hasFeature ? prop?.getGeometry() : undefined;

  const addressCodeToString = () => {
    const code = properties?.src_signgu_code + properties?.ld_emd_li_code;
    const thisAddress = addressList.filter(({ LAWD_CD }) => LAWD_CD === code)[0];
    return thisAddress?.SIDO_NM + ' ' + thisAddress?.SGG_NM + ' ' + thisAddress?.UMD_NM + ' ' + thisAddress?.RI_NM;
  };
  const pointType = () => {
    //  if (String(prop.getId()).split('.')[0] === 'dt_d007') {
    if (String(prop.getId()).includes('d007')) {
      return '지적도근점';
      //  } else if (String(prop.getId()).split('.')[0] === 'dt_d008') {
    } else if (String(prop.getId()).includes('d008')) {
      return '지적삼각보조점';
      //  } else if (String(prop.getId()).split('.')[0] === 'dt_d009') {
    } else if (String(prop.getId()).includes('d009')) {
      return '지적삼각점';
    }
  };

  const nameReturn = () => {
    //  if (String(prop.getId()).split('.')[0] === 'dt_d007') {
    if (String(prop.getId()).includes('d007')) {
      return properties?.lgstr_splmcpt_idntfc_nm || properties?.a5;
      //  } else if (String(prop.getId()).split('.')[0] === 'dt_d008') {
    } else if (String(prop.getId()).includes('d008')) {
      return properties?.lgstr_tgspt_idntfc_nm;
      //  } else if (String(prop.getId()).split('.')[0] === 'dt_d009') {
    } else if (String(prop.getId()).includes('d009')) {
      return properties?.lgstr_trgltpt_no_nm;
    }
  };

  console.log(properties);
  return (
    <InfoBoxSt.Wrap style={{ display: hasFeature ? 'block' : 'none', maxWidth: 'none', width: 'auto' }}>
      <InfoBoxSt.Head>
        지적측량기준점 정보
        <InfoBoxSt.Close type={'button'} onClick={close}>
          <span className={'hidden'}>닫기</span>
        </InfoBoxSt.Close>
      </InfoBoxSt.Head>
      <InfoBoxSt.Cont>
        <InfoBoxSt.Inner>
          <Table
            tableId={'pointInfoTable'}
            width={null}
            height={'auto'}
            caption={'지적측량기준점 정보'}
            tbodyId={'pointTable'}
            colgroup={['auto', '40%', '40%']}
            thead={null}
            tbody={
              <>
                <tr>
                  <th scope={'row'}>지적측량기준점 종류</th>
                  <td colSpan={2}>{hasFeature ? pointType() : ''}</td>
                </tr>
                <tr>
                  <th scope={'row'}>명칭 및 번호</th>
                  <td colSpan={2}>{hasFeature ? nameReturn() : ''}</td>
                </tr>
                <tr>
                  <th scope={'col'} rowSpan={2}>
                    구분
                  </th>
                  <th scope={'col'} colSpan={2}>
                    평면직각좌표
                  </th>
                </tr>
                <tr>
                  <th scope={'col'}>X(m)</th>
                  <th scope={'col'}>Y(m)</th>
                </tr>
                <tr>
                  <th scope={'row'}>세계측지계</th>
                  <td>{hasFeature ? Math.floor(geometries?.getCoordinates()[1] * 100) / 100 : ''}</td>
                  <td>{hasFeature ? Math.floor(geometries?.getCoordinates()[0] * 100) / 100 : ''}</td>
                </tr>
                <tr>
                  <th scope={'row'}>지역측지계</th>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <th scope={'row'}>소재지</th>
                  <td colSpan={2}>{hasFeature ? addressCodeToString() + '\n' + properties?.detail_lc || properties?.a4 : ''}</td>
                </tr>
              </>
            }
          />
          <p style={{ color: '#0076ff' }}>
            ※ 위 내용은 참고용으로 증명용 지적측량기준점성과 등본을 시군구 종합민원실에서 발급받아 확인하시기 바랍니다. <br />※ 위 좌표는 측량용으로 활용할 수 없습니다.
          </p>
        </InfoBoxSt.Inner>
      </InfoBoxSt.Cont>
    </InfoBoxSt.Wrap>
  );
};

export default PointInfoBox;
