import { useEffect, useCallback } from "react";

const MOUSE_DOWN = "mousedown";

// 외부 영역 클릭시, handleClose 실행
export function useOutsideClick(handleClose: () => void, ref: any, ref2?: any) {
   const handleClick = useCallback(
      (event: MouseEvent) => {
         if (ref?.current?.contains && !ref.current.contains(event.target)) {
            // ref2 있을 때
            if (ref2?.current) {
               if (ref2?.current?.contains && !ref2.current.contains(event.target)) {
                  handleClose();
               }
               return; // 조건 종료
            }
            // 기본 액션
            handleClose();
         }
      },
      [handleClose, ref, ref2],
   );

   useEffect(() => {
      document.addEventListener(MOUSE_DOWN, handleClick);
      return () => {
         document.removeEventListener(MOUSE_DOWN, handleClick);
      };
   }, [handleClick]);
}
