// [공통] feature 데이터 재요청시 사용
const xhr2HttpRequest = ({ method, url, vectorSource }) => {
  const xhr2 = new XMLHttpRequest();
  xhr2.open(method, url);

  const onError = () => {
    // vectorSource.removeLoadedExtent(extent);
    // failure(); // 실패 처리
  };

  xhr2.onload = () => {
    try {
      if (xhr2.status === 200) {
        const features: any = vectorSource.getFormat().readFeatures(xhr2.responseText);

        // * 중복 피쳐 필터링 * //
        // 1) 기존 소스 id 배열
        let ids = new Set(vectorSource.getFeatures().map((feature) => feature.getId()));
        // 2) bbox를 움직여 새로 요청한 response에서 기존에 있던 id 아닌 항목만 필터링
        const newFeatures = features.filter((feature) => !ids.has(feature.getId()));

        //   const newFeatures = features.filter((f) => vectorSource.getFeatures().filter((f2) => f2?.getId() !== f?.getId()));

        vectorSource.addFeatures(newFeatures);
        //   console.log(vectorSource.getFeatures());
      } else {
      }
    } catch (e) {
      console.log(e);
    }
  };
  xhr2.onerror = () => {
    // 에러시 실행할 내용
  };
  xhr2.send(''); // 두번째, 이후~ 요청
};

export default xhr2HttpRequest;
