import React, { useState, useEffect, useCallback } from 'react';
import OlCore from 'ol/OlCore';
import ObjectPolygon from 'ol/Object/ObjectPolygon';
import { getData, getFeatureData, request } from 'service/common';
import InfoBox from 'components/templates/InfoBox/InfoBox';
import styled from 'styled-components';
import useGetGeoJson from '../hooks/map/useGetGeoJson';
import { paramsBuilder, requestGeoJson, urlBuilder } from '../ol/util';
import PopupSiteInfo from '../components/templates/Map/PopupSiteInfo';
import Toolbar from '../components/templates/Map/Toolbar';
import RangeSlider from '../components/templates/Map/RangeSlider';
import MapType from '../components/templates/Map/MapType';
import MeasureModule from '../ol/Layer/MeasureModule';
import MapScale from '../components/templates/Map/MapScale';
import IcoTool01 from 'assets/images/toolbar/ico-tool-01.svg';
import IcoTool01H from 'assets/images/toolbar/ico-tool-01-hover.svg';
import IcoTool01A from 'assets/images/toolbar/ico-tool-01-active.svg';
import IcoTool02 from 'assets/images/toolbar/ico-tool-02.svg';
import IcoTool02H from 'assets/images/toolbar/ico-tool-02-hover.svg';
import IcoTool02A from 'assets/images/toolbar/ico-tool-02-active.svg';
import IcoTool03 from 'assets/images/toolbar/ico-tool-03.svg';
import IcoTool03H from 'assets/images/toolbar/ico-tool-03-hover.svg';
import IcoTool03A from 'assets/images/toolbar/ico-tool-03-active.svg';
import ClusterOverlay from '../components/templates/Map/ClusterOverlay';
import { Feature } from 'ol';
import ObjectPoint from '../ol/Object/ObjectPoint';
import PointInfoBox from '../components/templates/InfoBox/PointInfoBox';
import query from '../hooks/RQuery/utils';
import queryKeys from '../hooks/RQuery/keys/queryKeys';
import Login from '../components/templates/Map/Login';
import axios from 'axios';
import { getBubunByPnu } from 'service/multiPolygon';
import { address } from '../hooks/RQuery/service/apiUrl';
import ImgTest from '../components/templates/ImgTest';
import ObjectPhoto from '../ol/Object/ObjectPhoto';
import { getFarmData, getFarmmapObj, postBjdCd } from 'service/farm';
import ObjectFarm from 'ol/Object/ObjectFarm';
import FarmInfoBox from 'components/templates/InfoBox/FarmInfoBox';
import { getJusoFromCoord, getCoordFromJuso } from 'service/geoCoder';
import SelectAddress from 'components/templates/Map/SelectAddress';
import Input from '../components/atoms/Input';
import Checkbox from '../components/atoms/Checkbox';
import PopupTemplate from '../popup/PopupTemplate';
import PopupComponent from '../components/templates/Popup/PopupComponent';
import { POPUP_KEYS } from '../service/popupKeys';
import Button from '../components/atoms/Button';
import Select from '../components/atoms/Select';
import ToggleButton from '../components/atoms/ToggleButton';

import { CropInfo } from 'components/templates/Map/CropInfo';
import Header from '../components/templates/Map/Header';
import TopBtnGroup from 'components/templates/Map/TopBtnGroup';
import { useRecoilState } from 'recoil';
import { confirmState } from '../store/Atoms';
import TestViewList from '../components/TestViewList';

function Map(props) {
  const [confirmStatus, setConfirmStatus] = useRecoilState(confirmState);
  const [loginStatus, setLoginStatus] = useState(false);
  const [mapInstance, setMapInstance] = useState<OlCore>();
  const [objectPolygon, setObjectPolygon] = useState<ObjectPolygon>();
  const [objectPoint, setObjectPoint] = useState<ObjectPoint>();
  const [objectFarm, setObjectFarm] = useState<ObjectFarm>(); // 팜맵 객체
  const [addressList, setAddressList] = useState<any>([]);
  const [measure, setMeasure] = useState<MeasureModule>();
  const [objectPhoto, setObjectPhoto] = useState<ObjectPhoto>();
  const [measureStatus, setMeasureStatus] = useState(false);
  const [measureType, setMeasureType] = useState<'Polygon' | 'LineString'>('Polygon');
  const [mapScaleNum, setMapScaleNum] = useState('0 m');

  const [popState, setPopState] = useState<string>('');
  const [property, setProperty] = useState({}); //지적정보 팝업용 데이터
  const [isInfoState, setIsInfoState] = useState<boolean>(false); //지적정보 팝업
  const [farmInfo, setfarmInfo] = useState({}); // 팜맵 상세정보
  const [showFarmInfo, setShowFarmInfo] = useState(false); // 팜맵 상세정보 팝업

  const [centerAddress, setCenterAddress] = useState<string>(''); //현재 지도 center의 주소 (문자열)
  const [centerLevels, setCenterLevels] = useState(''); // 현재 지도 center의 주소 객체 (한글주소, 코드 등 포함)

  //툴바 버튼 on/off
  const [toolList, setToolList] = useState([
    { id: 0, name: '팜맵WFS', active: false, ico: IcoTool01, icoHover: IcoTool01H, icoActive: IcoTool01A },
    { id: 1, name: '지적도', active: false, ico: IcoTool01, icoHover: IcoTool01H, icoActive: IcoTool01A },
    { id: 2, name: '거리', active: false, ico: IcoTool02, icoHover: IcoTool02H, icoActive: IcoTool02A },
    { id: 3, name: '면적', active: false, ico: IcoTool03, icoHover: IcoTool03H, icoActive: IcoTool03A },
  ]);
  const [overlayState, setOverlayState] = useState(false);
  const [modifyData, setModifyData] = useState({
    layerName: '',
    featureId: null,
    coordinates: [],
    type: '',
    srid: null,
  });

  let isObjectReady = !!objectPolygon;

  const getAddress = async () => {
    const data = await axios.get(address);
    return data;
  };

  // 지도 이동시, center기준으로 주소 가져와서 표기
  useEffect(() => {
    if (isObjectReady) {
      // 지도 이동 완료 이벤트
      mapInstance?.onMoveEnd(async () => {
        // extent 좌표, center 좌표, proj, zoom
        console.log(mapInstance?.getMapInstance().getView().getViewStateAndExtent());
        const proj = mapInstance?.getMapInstance().getView().getProjection()?.getCode();
        const centerCoord = mapInstance?.getMapInstance().getView().getCenter();
        const zoom = mapInstance?.getMapInstance().getView().getZoom() || 0;

        try {
          const data = await getJusoFromCoord({ centerCoord, proj });

          const result = data.result?.[0];
          const addrText = result.text; // 전체 주소
          const level0 = result.structure.level0;
          const level1 = result.structure.level1; // 시
          const level2 = result.structure.level2; // 군구
          const level3 = result.structure.level3;
          const level4L = result.structure.level4L; // 동
          const level4AC = result.structure.level4AC; // 코드
          const level4LC = result.structure.level4LC; // 코드
          console.log('level4 AC', level4AC);
          console.log('level4 LC', level4LC);

          setCenterLevels({ ...result, zoom }); // 중심이 변경되면 주소 저장 -> SelectAddress로 전달용

          //  setCenterAddress(data.result?.[0].text);

          if (zoom < 8) {
            setCenterAddress('---');
          }
          if (8 <= zoom && zoom < 12) {
            setCenterAddress(level1);
          }
          if (12 <= zoom && zoom < 14) {
            setCenterAddress(`${level1} ${level2}`);
          }
          if (14 <= zoom) {
            setCenterAddress(`${level1} ${level2} ${level4L}`);
          }
        } catch (e) {
          console.log(e);
        }
      });
    }
  }, [isObjectReady, mapInstance]);

  // 팝맵 API
  //   const farmmapObj = (window as any).farmmapObj;
  //   useEffect(() => {
  //     console.log(farmmapObj.rootUri);
  //   }, []);

  //Openlayers 초기화
  useEffect(() => {
    if (!document.querySelector('.ol-viewport')) {
      const core = new OlCore();
      const objectPolygon = new ObjectPolygon(core);
      const objectPoint = new ObjectPoint(core);
      const objectFarm = new ObjectFarm(core);
      const objectPhoto = new ObjectPhoto(core);
      const measure = new MeasureModule(core);

      setMapInstance(core);
      setObjectPolygon(objectPolygon);
      setObjectPoint(objectPoint);
      setObjectFarm(objectFarm);
      setObjectPhoto(objectPhoto);
      setMeasure(measure);
    }
  }, []);

  // farm 정보 팝업
  const openFarmInfo = async (properties) => {
    console.log(properties);
    if (properties) {
      // Farm Map (PNU) - 여러개 데이터 경우 ?
      // const farmData = await getFarmData({ pnu: properties?.pnu });
      // const newProperties = farmData?.output?.farmmapData?.data;
      // console.log(newProperties);
      // 두가지 정보 합치기
      setfarmInfo({ ...properties });
      setShowFarmInfo(true); // 열기
    } else {
      setfarmInfo({});
      setShowFarmInfo(false); // 닫기
    }
  };

  const closeFarmInfo = () => {
    setShowFarmInfo(false);
    objectFarm?.clearSelectFeature();
  };

  //지적정보 팝업닫기
  const onCloseInfo = () => {
    setIsInfoState(false);
    objectPolygon?.drawInit();
  };

  // 2) 연속지적도 폴리곤 - 지도 클릭 콜백
  const getPolygonProp = useCallback(
    async (properties?: any) => {
      if (properties) {
        // 팜맵 OFF
        if (!toolList[0].active) {
          // 지적도 ON
          if (toolList[1].active && Number(mapInstance?.getMapInstance().getView().getZoom()) > 16.5) {
            let data = await getBubunByPnu({ pnu: properties?.pnu }); // FeatureCollection

            console.log(properties);
            console.log(data);

            let feature = data?.features?.[0];
            let newProperties = feature?.properties;

            // 두가지 정보 합치기
            setProperty({ ...properties, ...newProperties });
            setIsInfoState(true);
            // draw
            objectPolygon?.drawActive(data);
          }
        }
      }
    },
    [mapInstance, toolList, objectPolygon],
  );

  //툴바 버튼 클릭 토글
  const onToolbarClick = (id: number) => {
    const newArr = [...toolList];

    // 팜맵 클릭
    if (id === 0) {
      if (!newArr.filter((i) => i.id === 0)[0].active) {
        setfarmInfo({});
      }
      newArr.filter((i) => i.id === 0)[0].active = !newArr.filter((i) => i.id === 0)[0].active;
    } else if (id === 1) {
      //지적도 클릭
      if (!newArr.filter((i) => i.id === 1)[0].active) {
        setProperty({});
      }
      newArr.filter((i) => i.id === 1)[0].active = !newArr.filter((i) => i.id === 1)[0].active;
    } else {
      //거리 or 면적 클릭(상호작용)
      newArr
        .filter((i) => i.id !== 0)
        .map((i) => {
          if (i.id === id) {
            if (i.active) {
              i.active = false;
              setMeasureStatus(false);
            } else {
              i.active = true;
              setMeasureType(i.id === 1 ? 'LineString' : 'Polygon');
              setMeasureStatus(true);
            }
          } else {
            i.active = false;
          }
        });
    }

    setToolList(newArr);
  };

  useEffect(() => {
    if (isObjectReady) {
      objectPolygon?.addZoomEvent(); // 지도 줌 이벤트

      // objectPolygon?.getCoord(getCoordMap); // 1) callback
      objectPolygon?.addMapEvent(getPolygonProp); // 2) 지도 클릭 이벤트 - 콜백 전달
    }
  }, [isObjectReady, objectPolygon, getPolygonProp]);

  const [pointProp, setPointProp] = useState<any>({}); // Feature

  // wfs 구역 경계
  useEffect(() => {
    if (isObjectReady) {
      objectPolygon?.drawSido(); //시도
      objectPolygon?.drawSigungu(); // 시군구
      objectPolygon?.drawEmd(); // 읍면동

      // mapInstance?.onMoveEnd(() => {
      //   setMapScaleNum(document.getElementsByClassName('ol-scale-line-inner')[0].innerHTML || '0 m');
      //   console.log(mapInstance?.getMapInstance().getView().getViewStateAndExtent());
      // });
    }
  }, [isObjectReady]);

  // 지적도 on/off state 추가
  useEffect(() => {
    if (isObjectReady) {
      objectPolygon?.drawBubun(toolList[1].active); // 연속지적도
      // objectPolygon?.drawBubun2(); // 연속지적도 WFS /ned/wfs/~
    }
  }, [isObjectReady, toolList[1].active]);

  // 기준점 Point 그리기
  useEffect(() => {
    if (isObjectReady) {
      objectPoint?.drawDogeun(); // 지적도근점
      objectPoint?.drawTriangle(); // 지적삼각점
      objectPoint?.drawAssistant(); // 지적삼각보조점
    }
  }, [isObjectReady, objectPoint]);

  // 기준점 point 이벤트 추가
  useEffect(() => {
    if (isObjectReady) {
      objectPoint?.onClickPoint(setPointProp); // click single point
      objectPoint?.dragBoxPoint(); // dragBox - multiple points select
    }
  }, [isObjectReady, objectPoint]);

  //측정
  useEffect(() => {
    measure?.destroy(measureStatus);
    measure?.addInteraction(measureStatus, measureType);
  }, [measureStatus, measureType]);

  //줌레벨 변경시 오버레이 닫기
  useEffect(() => {
    mapInstance
      ?.getMapInstance()
      .getView()
      .on('change:resolution', () => {
        setOverlayState(false);
      });
  }, [mapInstance]);
  // console.log(mapInstance?.getMapInstance().getView().calculateExtent(mapInstance?.getMapInstance().getSize()));

  // 상세정보 닫기 이벤트
  const closeInfoBox = () => {
    setPointProp({});
    // select clear
    objectPoint?.onClickPointClear();
  };

  // 위치 이동
  const clickMoveTo = async (juso) => {
    const proj = mapInstance?.getMapInstance().getView().getProjection()?.getCode();
    const data = await getCoordFromJuso({ juso, proj }); // 브이월드 API 요청 (주소로 좌표 구하기)
    const point = data.result.point;
    const coord = [point.x || 0, point.y || 0];
    mapInstance?.moveToCoord(coord);

    //   mapInstance
    //        //     ?.getMapInstance()
    //        //     .getView()
    //        //     .fit(extent, { padding: [170, 50, 30, 150], minResolution: 50 });
  };

  const [option, setOption] = useState([
    { text: 'askdlmad', value: '1', active: true },
    { text: '22', value: '12', active: false },
  ]);
  return (
    <>
      <div style={{ position: 'fixed', top: 100, left: 0, zIndex: 10 }}>
        <TestViewList />
      </div>

      <div style={{ width: '100%', height: '100%', position: 'relative' }} id={'mapContainer'}>
        {/* 현장 선택 & 회차 선택 */}
        <Header />
        {/* 주소 선택 */}
        <SelectAddress clickMoveTo={clickMoveTo} centerLevels={centerLevels} />
        {/* 상단 버튼 모음 (사진중복확인 등) */}
        <TopBtnGroup />

        {/* {loginStatus ? <ImgTest mapInstance={mapInstance} objectPhoto={objectPhoto} isObjectReady={isObjectReady} objectFarm={objectFarm} toolList={toolList} openFarmInfo={openFarmInfo} /> : null} */}
        {/* <Login loginStatus={loginStatus} setLoginStatus={setLoginStatus} /> */}
        {/* {loginStatus ? <Toolbar onToolbarClick={onToolbarClick} toolList={toolList} /> : null} */}

        <RangeSlider mapInstance={mapInstance} />

        <MapScale mapScaleNum={mapScaleNum} id={'scaleBar'} />
        <ZoomLevel />

        {/* 팜맵 상세정보 팝업 */}
        <FarmInfoBox property={farmInfo} active={showFarmInfo} show={showFarmInfo} close={closeFarmInfo} />
        {/* 지적정보 팝업 */}
        <InfoBox property={property} active={toolList[1].active && !!Object.keys(property).length} isInfoState={isInfoState} onCloseInfo={onCloseInfo} />

        <PointInfoBox prop={pointProp} close={closeInfoBox} addressList={addressList} />

        {/* 작물 정보 버튼 & 팝업 */}
        <CropInfo />

        <PopupComponent popState={popState} setPopState={setPopState} />
      </div>
    </>
  );
}

export default Map;

export function ZoomLevel() {
  const ZoomLevelSt = {
    Wrap: styled.div`
      position: fixed;
      top: 16px;
      left: 0;
      z-index: 9;
      background-color: #fff;
      display: none;
    `,
  };
  return <ZoomLevelSt.Wrap id="zoomLevel"></ZoomLevelSt.Wrap>;
}
