import { useState, useCallback } from 'react';

type UseOnChangeProps = [
  any,
  React.Dispatch<React.SetStateAction<object>>,
  (e: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLLIElement> | React.ChangeEvent<HTMLTextAreaElement>) => void,
  () => void,
  (name: string) => void,
  (value: any, name?: string) => void,
];

/* custom hook 만들기 */
function useOnChange(initialForm: object): UseOnChangeProps {
  const [form, setForm] = useState(initialForm);

  // change
  const onChange = (e: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLLIElement> | React.ChangeEvent<HTMLTextAreaElement>, str?: string): void => {
    if (str) {
      const { ariaValueText } = e.target as HTMLLIElement; // li 태그 (select 박스)
      setForm({
        ...form,
        [str]: ariaValueText,
      });
    } else {
      const { id, name, value, type, checked } = e.target as HTMLInputElement;

      if (type === 'radio') {
        setForm({
          ...form,
          [id]: checked,
        });
      } else {
        setForm({
          ...form,
          [name]: type === 'checkbox' ? checked : value,
        });
      }
    }
  };

  //모든 input => form 초기화
  const resetAll = useCallback((): void => setForm(initialForm), [initialForm]);

  //특정 input 초기화
  const resetOne = useCallback((name: string): void => {
    setForm((prev) => {
      return { ...prev, [name]: '' };
    });
  }, []);

  // date picker 일때
  const onChangeDate = useCallback((value: any, name?: any): void => {
    setForm((prev) => ({
      ...prev,
      [name]: value.format('YYYY-MM-DD'),
    }));
  }, []);

  return [form, setForm, onChange, resetAll, resetOne, onChangeDate];
}

export default useOnChange;
