import React from 'react';
import styled from 'styled-components';
interface MapScaleProps {
  mapScaleNum:string
  id:string
}
const MapScaleStyle = {
  Wrap:styled.div`
      position:absolute;left:0;bottom:0;height:28px;display:flex;padding:0 16px;
      z-index:10;background:rgba(255,255,255,.8);align-items:center;
    `,
  Label:styled.p`
      color:#1b1b1b;font-size:14px;font-weight:300;margin-right:10px;
    `,
  Bar:styled.div`
      height:7px;border:2px solid #1b1b1b;border-top:0;
      .ol-scale-line-inner{font-size:0;}
    `
}
const MapScale = ({mapScaleNum,id}: MapScaleProps) => {
  return (
    <MapScaleStyle.Wrap>
      <MapScaleStyle.Label>{mapScaleNum}</MapScaleStyle.Label>
      <MapScaleStyle.Bar id={id}></MapScaleStyle.Bar>
    </MapScaleStyle.Wrap>
  );
};

export default MapScale;