import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

export const TableStyle = {
  Wrap: styled.div<{ width?: number; height?: number }>`
    position: relative;
    overflow: auto;
    width: ${(p) => (p.width ? p.width + 'px' : '100%')};
    height: ${(p) => (p.height ? p.height : 'auto')};
  `,
  Thead: styled.div<{ width?: number; height?: number }>`
    position: ${(p) => (p.height ? 'relative' : 'sticky')};
    z-index: 10;
    top: ${(p) => (p.height ? 'auto' : 0)};
    width: 100%;
    margin: 0 auto;

    table {
      width: 100%;
      box-sizing: border-box;

      th {
        color: #000;
        border: 1px solid #000;
        box-sizing: border-box;
        height: 48px;
        font-size: 14px;
        font-weight: 600;
        background: #fff;

        &.txtLeft {
          text-align: left;
          padding-left: 20px;
        }
      }
    }
  `,
  Tbody: styled.div<{ tbodyHeight?: number }>`
    position: sticky;
    top: 48px;
    table {
      width: 100%; //border-collapse:separate;
      tr {
        box-sizing: border-box;
        &.active {
          td {
            background: rgba(0, 0, 255, 0.2);
          }
        }

        th {
          color: #000;
          border: 1px solid #000;
          box-sizing: border-box;
          height: 24px;
          font-size: 14px;
          font-weight: 600;
          background: #ccc;

          &.txtLeft {
            text-align: left;
            padding-left: 20px;
          }
        }
        td {
          text-align: center;
          font-size: 14px;
          font-weight: 600;
          color: #000;
          box-sizing: border-box;
          transition: 0.4s;
          height: 24px;
          border: 1px solid #000;
          &.txtLeft {
            text-align: left;
            padding-left: 20px;
          }
        }
      }
    }
  `,
};
const Table = ({ thead, tbody, caption, colgroup, height, tbodyId, width, tableId }) => {
  return (
    <TableStyle.Wrap height={height} width={width} id={tableId}>
      {thead ? (
        <TableStyle.Thead>
          <table>
            <caption>{caption}</caption>
            <colgroup>
              {colgroup?.map((i, index) => {
                return <col key={index} style={{ width: i }} />;
              })}
            </colgroup>
            <thead>{thead}</thead>
          </table>
        </TableStyle.Thead>
      ) : null}
      <TableStyle.Tbody id={tbodyId}>
        <table>
          <caption>{caption}</caption>
          <colgroup>
            {colgroup?.map((i, index) => {
              return <col key={index} style={{ width: i }} />;
            })}
          </colgroup>
          <tbody>{tbody}</tbody>
        </table>
      </TableStyle.Tbody>
    </TableStyle.Wrap>
  );
};

export default Table;
