import { useState } from 'react';
import styled from 'styled-components';
import IcoMenu from 'assets/images/map/ico-menu.svg';
import CropInfoBox from '../InfoBox/CropInfoBox';

const CropInfoBtnSt = {
  Button: styled.button`
    position: fixed;
    top: 80px;
    right: 18px;
    z-index: 10;
    width: 48px;
    height: 48px;
    background-color: #024751;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .icoMenu {
      display: inline-block;
      width: 24px;
      height: 24px;
      background: url(${IcoMenu}) no-repeat center;
    }
  `,
};

export function CropInfo() {
  const [showCropInfo, setShowCropInfo] = useState(false); // 작물 정보 팝업 오픈

  return (
    <>
      <CropInfoBtnSt.Button onClick={() => setShowCropInfo(true)}>
        <span className="icoMenu"></span>
      </CropInfoBtnSt.Button>

      {showCropInfo && <CropInfoBox close={() => setShowCropInfo(false)} />}
    </>
  );
}
