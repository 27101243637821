import { useNavigate } from 'react-router-dom';

function NotFound() {
  const navigate = useNavigate();
  const onClickBack = () => {
    navigate('/');
  };
  return (
    <div>
      <p className="main">페이지를 찾을 수 없습니다.</p>
      <span className="sub">
        원하시는 결과를 찾을 수 없습니다.
        <br />
        올바른 URL을 입력하였는지 확인하세요.
      </span>
      <button onClick={onClickBack}>메인페이지 돌아가기</button>
    </div>
  );
}

export default NotFound;
