import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import IcoPassword from '../../assets/images/input/ico-password.svg';
import IcoPasswordHide from '../../assets/images/input/ico-password-hide.svg';
import { maxLengthCheck } from 'util/global';

export interface InputProps {
  id?: string;
  name?: string;
  type?: string;
  value?: string | number;
  width?: string | number;
  height?: number;
  error?: boolean;
  fontSize?: number;
  style?: React.CSSProperties;
  disabled?: boolean;
  placeholder?: string;
  unit?: string;
  leftIco?: any;
  maxLength?: number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const InputStyle = {
  Wrap: styled.div<InputProps>`
    position: relative;
    display: flex;
    align-items: center;
    width: ${(p) => (p.width ? (typeof p.width === 'number' ? p.width + 'px' : p.width) : '16px')};
    height: ${(p) => (p.height ? p.height + 'px' : '16px')};
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid ${(p) => (p.error ? '#F4323D' : '#e5e5ec')};
    overflow: hidden;
    &:focus-within {
      border-color: #00b074;
    }
    .unit {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      color: gray;
      font-size: 14px;
      pointer-events: none; /* 클릭이 input으로 전달되도록 설정 */
    }
    .leftIco {
      width: 24px;
      height: 24px;
      display: block;
      background: ${(p) => `url(${p.leftIco}) no-repeat center`};
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%); /* 아이콘을 세로 중앙 정렬 */
    }
  `,
  Input: styled.input<InputProps>`
    /* flex: 1; */
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    color: #025143;
    padding: ${(p) => (p.unit ? '0 30px 0 16px' : p.leftIco ? '0 16px 0 54px' : '0 16px')};
    box-sizing: border-box;
    margin: 0;
    font-size: ${(p) => (p.fontSize ? p.fontSize + 'px' : '14px')};
    font-weight: 500;
    &::placeholder {
      color: #a5a5a5;
      font-weight: 400;
      font-size: ${(p) => (p.fontSize ? p.fontSize + 'px' : '14px')};
    }
    &:disabled {
      background: #f1f1f5;
    }
    &:hover {
      background: #e5f8e8;
      &:disabled {
        background: #f1f1f5;
      }
    }
  `,
  ToggleType: styled.button<{ isPassword: boolean }>`
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background: url(${(p) => (p.isPassword ? IcoPasswordHide : IcoPassword)}) no-repeat center;
  `,
};

const Input = ({ type = 'text', id, name, value, width, height, fontSize, error, disabled, style, onChange, placeholder, unit, leftIco, maxLength }: InputProps) => {
  const [isPassword, setIsPassword] = useState(true);
  const onToggle = () => {
    setIsPassword(!isPassword);
  };
  return (
    <InputStyle.Wrap width={width} height={height} error={error} style={style} leftIco={leftIco}>
      {leftIco && <span className="leftIco"></span>}
      <InputStyle.Input
        type={type === 'password' ? (isPassword ? 'password' : 'text') : type}
        leftIco={leftIco}
        id={id}
        name={name}
        onChange={onChange}
        fontSize={fontSize}
        disabled={disabled}
        placeholder={placeholder}
        defaultValue={value}
        maxLength={maxLength}
        onKeyUp={maxLength ? maxLengthCheck : undefined}
      />
      {unit && <span className="unit">{unit}</span>}
      {type === 'password' ? (
        <InputStyle.ToggleType type={'button'} onClick={onToggle} isPassword={isPassword}>
          <span className={'hidden'}>보이기/숨기기</span>
        </InputStyle.ToggleType>
      ) : null}
    </InputStyle.Wrap>
  );
};

export default Input;
