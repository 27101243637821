import React, { useState } from 'react';
import styled from 'styled-components';
import Logo from '../../../assets/images/login/header-logo.svg';
import Select from '../../atoms/Select';
const HeaderStyle = {
  Contain: styled.header`
    position: absolute;
    z-index: 10;
    left: 16px;
    top: 16px;
    display: flex;
    align-items: center;
  `,
  Logo: styled.h1`
    width: 122px;
    height: 48px;
    background: #024751 url(${Logo}) no-repeat center;
  `,
};

const Header = () => {
  const [siteList, setSiteList] = useState([
    { text: '제주한림망고밭', value: 1, active: true },
    { text: '제주에인감귤밭', value: 2, active: false },
    { text: '제주성한사과과수원', value: 3, active: false },
  ]);

  const [round, setRound] = useState([
    { text: '1회차', value: 1, active: true },
    { text: '2회차', value: 2, active: false },
    { text: '3회차', value: 3, active: false },
  ]);

  const onChange = (e, option, setOption) => {
    const value = e.target.ariaValueText;
    const newArr: any = [];
    option.map((opt) => {
      newArr.push({ text: opt.text, value: opt.value, active: value === String(opt.value) });
    });
    setOption(newArr);
    console.log(option);
  };

  return (
    <HeaderStyle.Contain>
      <HeaderStyle.Logo></HeaderStyle.Logo>
      <Select width={264} height={48} option={siteList} $isHeader={true} onChange={(e) => onChange(e, siteList, setSiteList)} />
      <Select width={104} height={48} option={round} style={{ marginLeft: 10 }} onChange={(e) => onChange(e, round, setRound)} />
    </HeaderStyle.Contain>
  );
};

export default Header;
