import PointModule from '../Layer/PointModule';
import OlCore from '../OlCore';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import Select from 'ol/interaction/Select.js';
import { transWgsTo5186 } from '../projection';
import { click } from 'ol/events/condition';
import Style from 'ol/style/Style';
import { Circle, Fill } from 'ol/style.js';

class ObjectPhoto extends PointModule {
  private PhotoLayer: VectorLayer<VectorSource>;
  private photoSource!: VectorSource;
  private photoFeatures: Feature[] = [];
  private clickEvent: Select = new Select();
  constructor(core: OlCore) {
    super(core);
    // 지적도근점 레이어
    this.PhotoLayer = new VectorLayer<VectorSource>({
      properties: {
        id: 'PhotoLayer',
      },
      visible: true,
      zIndex: 10,
      // minZoom: 16.5,
      // maxZoom: Infinity,
    });

    this.setLayers([this.PhotoLayer]);
  }

  public draw(datas) {
    const features: any = [];
    datas.map((i) => {
      features.push(
        new Feature({
          geometry: new Point(transWgsTo5186([i.lon, i.lat])),
          properties: i,
        }),
      );
    });
    this.photoFeatures = features;
    this.photoSource = this.createSource(this.photoFeatures);
    this.PhotoLayer.setSource(this.photoSource);
    this.core.getMapInstance().getView().fit(this.photoSource.getExtent(), { duration: 500 });
  }

  public onClickFeature(state, setState) {
    this.clickEvent = new Select({
      layers: [this.PhotoLayer],
      condition: click,
      style: (e) => {
        return new Style({
          image: new Circle({
            radius: 10,
            fill: new Fill({ color: 'red' }),
          }),
        });
      },
    });

    this.clickEvent.on('select', (e) => {
      const newArr = [...state];
      if (e.selected.length) {
        newArr.map((i) => {
          i.active = i.dronId === e.selected[0].getProperties().properties.dronId;
        });
        setState(newArr);
        (document.getElementById(`dronTable`) as HTMLDivElement).scrollTo({
          behavior: 'smooth',
          top: (document.getElementById(`dron_${e.selected[0].getProperties().properties.dronId}`) as HTMLLIElement).offsetTop,
        });
      } else {
        newArr.map((i) => {
          i.active = false;
        });
        setState(newArr);
      }
    });
    this.core.mapInstance.addInteraction(this.clickEvent);
  }
  public onClickRow(row, isFit) {
    this.clickEvent.getFeatures().clear();
    const rowFeature = this.photoFeatures.find((feature) => feature.getProperties().properties.dronId === row.dronId);
    this.clickEvent.getFeatures().push(rowFeature!);
    if (isFit) {
      this.core.mapInstance.getView().fit((rowFeature!.getGeometry() as any).getExtent(), { duration: 500, maxZoom: 16 });
    }
    console.log(this.clickEvent.getFeatures());
  }
}
export default ObjectPhoto;
