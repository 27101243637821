import OlCore from 'ol/OlCore';
import VectorModule from 'ol/Layer/VectorModule';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import GeoJSONFormat from 'ol/format/GeoJSON';
import * as Format from 'ol/format';
import * as loadingstrategy from 'ol/loadingstrategy';
import { Circle, Fill, Stroke, Style, Text } from 'ol/style.js';

import { Feature } from 'ol';
import { FeatureLike } from 'ol/Feature';
import xhr2HttpRequest from 'ol/util/xhr2HttpRequest';

import GreaterThanOrEqualTo from 'ol/format/filter/GreaterThanOrEqualTo';

import { getFarmMapWFS } from 'service/farm';
import { Select } from 'ol/interaction';
import { MultiPolygon, Point, Polygon } from 'ol/geom';
import PolygonModule from 'ol/Layer/PolygonModule';
import { baseProjection, targetProjection } from '../projection';
import { click } from 'ol/events/condition';
import ObjectPhoto from './ObjectPhoto';
import objectPhoto from './ObjectPhoto';
import data from '../../assets/loading/data';

// 연속 지적도 테스트용 레이어
class ObjectFarm extends VectorModule {
  private PolygonLayer: VectorLayer<VectorSource>;
  public FarmLayer: VectorLayer<VectorSource>;

  public FarmSource!: VectorSource;

  private FarmSelect!: Select;

  constructor(core: OlCore) {
    super(core);

    //팜맵
    this.FarmLayer = new VectorLayer<VectorSource>({
      properties: {
        id: 'FarmLayer',
      },
      visible: true,
      zIndex: 9,
      minZoom: 16.5,
      maxZoom: Infinity,
    });

    //active polygon
    this.PolygonLayer = new VectorLayer<VectorSource>({
      properties: {
        id: 'PolygonLayer',
      },
      visible: true,
      zIndex: 9,
      minZoom: 16.5,
      maxZoom: Infinity,
    });

    this.setLayers([this.FarmLayer, this.PolygonLayer]);
  }

  //[Draw]
  public drawFarmWFS(show: boolean, dataList, setDataList) {
    if (show) {
      // 벡터 소스 정의
      this.FarmSource = new VectorSource({
        strategy: loadingstrategy.bbox,
        format: new GeoJSONFormat(),
        // url: function (extent) {
        //   var strUrl = getFarmMapWFS({ bbox: extent, startindex: 0 });
        //   return strUrl;
        // },
        loader: (extent, resolution, projection, success: any, failure: any) => {
          var strUrl = getFarmMapWFS({ bbox: extent, startindex: 0 });

          const xhr = new XMLHttpRequest();
          xhr.open('GET', strUrl);

          const onError = () => {
            this.FarmSource.removeLoadedExtent(extent);
            failure(); // 실패 처리
          };
          xhr.onerror = onError;
          xhr.onload = async () => {
            if (xhr.status === 200) {
              // 응답 response
              const response = JSON.parse(xhr.response);
              let totalFeatures = response?.totalFeatures;
              let count = totalFeatures / 200;
              const newDataList = [...dataList];
              // totalFeatures >= 1000일 때 요청
              for (let i = 1; i < count; i++) {
                let startindex = 200 * i;
                let strUrl = getFarmMapWFS({ bbox: extent, startindex: startindex });
                // [공통] 데이터 재요청 코드
                xhr2HttpRequest({ method: 'GET', url: strUrl, vectorSource: this.FarmSource }); // http Request
              }

              //피쳐 생성 후 추가
              const features: any = (this.FarmSource as any).getFormat().readFeatures(xhr.responseText);
              this.FarmSource.addFeatures(features);
              const photoSource = (this.core.mapInstance.getAllLayers().find((layer) => layer.get('id') === 'PhotoLayer') as VectorLayer<VectorSource>).getSource();
              photoSource?.getFeatures().map((i) => {
                this.FarmSource.getFeatures().map((farmFeature) => {
                  if (farmFeature.getGeometry()?.intersectsCoordinate((i.getGeometry() as any).getCoordinates())) {
                    newDataList.filter((data) => data.dronId === i.getProperties().properties.dronId)[0].farmData = farmFeature.getProperties();
                    // i.setProperties({ ...i.getProperties(), properties: { ...i.getProperties().properties, farmMapData: farmFeature.getProperties() } });
                  }
                });
              });
              setDataList(newDataList);
              success(features); // 성공 처리
            } else {
              onError();
            }
          };
          xhr.send(); // 첫번쨰 요청
        },
      });
      // 레이어 스타일
      this.FarmLayer.setStyle((feature) => {
        return this.getStyle('farm', feature);
      });

      // 레이어에 벡터 소스 추가
      this.FarmLayer.setSource(this.FarmSource);
    }

    // 레이어 show
    this.FarmLayer.setVisible(show); // ON,OFF
  }

  // [Select] 폴리곤
  public addSelectEvent(callback: any) {
    this.core.mapInstance.removeInteraction(this.FarmSelect); // 초기화

    this.FarmSelect = new Select({
      condition: click,
      layers: [this.FarmLayer],
      style: (feature) => this.getStyle('farmActive', feature),
    });

    this.FarmSelect.on('select', (evt) => {
      let feature = evt?.selected[0];
      let collection = this.FarmSelect.getFeatures();
      let selectedFeatures = collection?.getArray();

      if (feature) {
        let properties = feature.getProperties();
        console.log(properties);
        if (callback) {
          callback(properties);
        }
      } else {
        callback(null);
      }
    });

    this.core.mapInstance.addInteraction(this.FarmSelect);
  }
  public clearSelectFeature() {
    this.FarmSelect.getFeatures().clear();
  }

  // [X] 클릭한 폴리곤 active
  public drawActive(data: any) {
    if (data) {
      console.log(data);

      const features = this.createPolygonFeatures(data, 'farmPolygon');

      const source = this.createSource(features);

      //  for (var i in features) features[i].setStyle(style);
      this.PolygonLayer.setStyle((feature) => this.getStyle('farmActive', feature));

      // vector layer
      this.PolygonLayer.setSource(source);
      this.PolygonLayer.setVisible(true);
    } else {
      this.PolygonLayer.setVisible(false);
    }
  }

  public createPolygonFeatures(datas: any[], layerId: string) {
    return datas?.map((data) => {
      return this.createPolygonFeature(data, layerId);
    });
  }

  public createPolygonFeature(data: any, layerId: string) {
    const polygonFeature = new Feature({
      geometry: data.geometry.type === 'Polygon' ? new Polygon(data.geometry.coordinates) : new MultiPolygon(data.geometry.coordinates),
      properties: { ...data },
    });
    // polygonFeature.getGeometry()?.transform(targetProjection, baseProjection);
    return polygonFeature;
  }

  // ------------ STYLE -------------- //
  //[Style]
  public getStyle(type, feature) {
    if (type === 'farm') {
      return new Style({
        fill: new Fill({ color: 'rgba(141, 249, 129, 0.2)' }),
        stroke: new Stroke({ color: '#8df981', width: 2 }),
        text: new Text({
          //  textAlign: 'center',
          //  font: '20px Verdana',
          //  scale: 1,
          //  text: feature?.getProperties()?.stdg_cd || '',
          //  fill: new Fill({ color: '#000' }),
          //  stroke: new Stroke({ color: '#fff', width: 3 }),
        }),
      });
    }
    if (type === 'farmActive') {
      return new Style({
        fill: new Fill({ color: 'rgba(249, 146, 248, 0.5)' }),
        stroke: new Stroke({ color: '#ff4e4e', width: 3 }),
        text: new Text({
          //  textAlign: 'center',
          //  font: '20px Verdana',
          //   scale: 1,
          //  text: feature?.getProperties()?.stdg_cd || '',
          //  fill: new Fill({ color: '#000' }),
          //  stroke: new Stroke({ color: '#fff', width: 3 }),
        }),
      });
    }
  }
}
export default ObjectFarm;
