import React from 'react';
import PopupTemplate from './PopupTemplate';
import styled from 'styled-components';
import IcoMessage from '../assets/images/popup/ico-message.svg';
import IcoO from '../assets/images/popup/ico-o.svg';
import IcoX from '../assets/images/popup/ico-x.svg';
import Pagination from '../components/atom/Pagination';
import Table from '../components/atoms/Table';

const Content = {
  Inner: styled.div`
    padding: 16px 0 0;
  `,

  Head: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    height: 40px;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid #d9d9d9;
    margin-bottom: 6px;
    background: #fff;
    strong {
      font-size: 14px;
      font-weight: 500;
      color: #00b074;
      margin-right: auto;
    }
    span {
      font-size: 14px;
      font-weight: 600;
      color: #024751;
    }
    &:before {
      content: '';
      width: 16px;
      height: 16px;
      margin-right: 6px;
      background: url(${IcoMessage}) no-repeat center;
    }
  `,
  Cont: styled.div``,
  CenterBox: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 17px 0 18px;
  `,
};

const PopupResult = ({ onClose }) => {
  return (
    <PopupTemplate
      title={'전체 결과 보기'}
      width={544}
      $type={3}
      isDimmed={true}
      onClose={onClose}
      content={
        <Content.Inner>
          <Content.Head>
            <strong>전체 필지의 사진 유무 및 업로드 결과는 다음과 같습니다.</strong>
            <span>총 927필지</span>
          </Content.Head>
          <Content.Cont>
            <Table
              caption={'전체 결과'}
              colgroup={['auto', 120, 127]}
              thead={
                <tr>
                  <th scope={'col'}>팜맵 ID</th>
                  <th scope={'col'}>사진 유무</th>
                  <th scope={'col'}>사진 업로드 결과</th>
                </tr>
              }
              tbody={
                <>
                  <tr>
                    <td>2312341</td>
                    <td>
                      <img src={IcoO} alt={'O'} />
                    </td>
                    <td>
                      <img src={IcoX} alt={'X'} />
                    </td>
                  </tr>
                  <tr>
                    <td>2312341</td>
                    <td>
                      <img src={IcoO} alt={'O'} />
                    </td>
                    <td>
                      <img src={IcoX} alt={'X'} />
                    </td>
                  </tr>
                  <tr>
                    <td>2312341</td>
                    <td>
                      <img src={IcoO} alt={'O'} />
                    </td>
                    <td>
                      <img src={IcoX} alt={'X'} />
                    </td>
                  </tr>
                  <tr>
                    <td>2312341</td>
                    <td>
                      <img src={IcoO} alt={'O'} />
                    </td>
                    <td>
                      <img src={IcoX} alt={'X'} />
                    </td>
                  </tr>
                  <tr>
                    <td>2312341</td>
                    <td>
                      <img src={IcoO} alt={'O'} />
                    </td>
                    <td>
                      <img src={IcoX} alt={'X'} />
                    </td>
                  </tr>
                  <tr>
                    <td>2312341</td>
                    <td>
                      <img src={IcoO} alt={'O'} />
                    </td>
                    <td>
                      <img src={IcoX} alt={'X'} />
                    </td>
                  </tr>
                  <tr>
                    <td>2312341</td>
                    <td>
                      <img src={IcoO} alt={'O'} />
                    </td>
                    <td>
                      <img src={IcoX} alt={'X'} />
                    </td>
                  </tr>
                  <tr>
                    <td>2312341</td>
                    <td>
                      <img src={IcoO} alt={'O'} />
                    </td>
                    <td>
                      <img src={IcoX} alt={'X'} />
                    </td>
                  </tr>
                  <tr>
                    <td>2312341</td>
                    <td>
                      <img src={IcoO} alt={'O'} />
                    </td>
                    <td>
                      <img src={IcoX} alt={'X'} />
                    </td>
                  </tr>
                  <tr>
                    <td>2312341</td>
                    <td>
                      <img src={IcoO} alt={'O'} />
                    </td>
                    <td>
                      <img src={IcoX} alt={'X'} />
                    </td>
                  </tr>
                </>
              }
            />

            <Content.CenterBox>
              <Pagination />
            </Content.CenterBox>
          </Content.Cont>
        </Content.Inner>
      }
    />
  );
};

export default PopupResult;
