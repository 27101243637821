import axios from 'axios';
import { targetProjection } from '../projection';
import { View } from 'ol';

export const requestGeoJson = async (url: string): Promise<any> => {
  try {
    const { data } = await axios.get<any>(url, {
      responseType: 'json',
    });

    if (data) {
      const { features, numberReturned, bbox } = data;
      return {
        features: features,
        length: numberReturned,
        bbox: bbox,
      };
    }
  } catch (error) {
    console.log(error);
  }
};

export const requestGeoJson2 = async (url: string): Promise<any> => {
  try {
    const { data } = await axios.get<any>(url, {
      responseType: 'json',
    });

    if (data) {
      console.log(data);
    }
  } catch (error) {
    console.log(error);
  }
};

export const paramsBuilder = (typeName: string, srs: string) => {
  return {
    typeName: typeName,
    VERSION: '1.1.0',
    SERVICE: 'WFS',
    outputFormat: 'application/json',
    REQUEST: 'GetFeature',
    SRS: srs,
  };
};

export const paramsBuilder2 = (srs: string) => {
  return {
    VERSION: '1.1.0',
    SERVICE: 'WMS',
    outputFormat: 'application/json',
    REQUEST: 'GetFeatureInfo',
    SRS: srs,
    FORMAT: 'image/png',
    TRANSPARENT: true,
    QUERY_LAYERS: 'koreazinc:line',
    STYLES: 'koreazinc:test_line_style',
    LAYERS: 'koreazinc:line',
    exceptions: 'application/vnd.ogc.se_inimage',
    INFO_FORMAT: 'INFO_FORMAT',
    FEATURE_COUNT: 50,
  };
};

export const urlBuilder = (host: string, query = {}) => {
  const param = Object.entries(query)
    .map(([key, value]: any) => (value ? `${key}=${encodeURIComponent(value)}` : ''))
    .join('&');
  return `${host}?${param}`;
};

export const LAYERS_TYPE = {
  POLYGON1: 'mv:kz_bml_1001_as',
  POLYGON2: 'mv:kz_bml_1002_as',
  POLYGON3: 'mv:kz_bml_cbnd_as',
  POINT1: 'mv:kz_bml_symb_ps',
  TEST1: 'mv:kz_bml_admb_ls',
};

export const url = [
  urlBuilder('/geoserver/koreazinc/wfs', paramsBuilder(LAYERS_TYPE.POLYGON1, targetProjection)),
  urlBuilder('/geoserver/koreazinc/wfs', paramsBuilder(LAYERS_TYPE.POLYGON2, targetProjection)),
  urlBuilder('/geoserver/koreazinc/wfs', paramsBuilder(LAYERS_TYPE.POLYGON3, targetProjection)),
  urlBuilder('/geoserver/koreazinc/wfs', paramsBuilder(LAYERS_TYPE.POINT1, targetProjection)),
  urlBuilder('/geoserver/koreazinc/wfs', paramsBuilder(LAYERS_TYPE.TEST1, targetProjection)),
  urlBuilder('/geoserver/koreazinc/wms', paramsBuilder2(targetProjection)),
];

export const idExt = [-4112949.2782165394, -5744092.574531809, 3302316.0109293596, -2064639.548321521];
export const idCenter = [-405316.6336435899, -3904366.061426665];
export const koExt = [-596583.7770299637, 21936.82390401489, 1158725.5547540444, 892920.9331605749];

export const koCenter = [281070.88886204036, 457428.8785322949];
export const vtExt = [-4183694.3879885944, -2708663.691110687, 75257.1317656571, -595372.8718623775];
