import React, { ReactNode } from 'react';
import { atom } from 'recoil';
import { PopupProps } from '../popup/PopupTemplate';
import { ConfirmProps } from '../components/templates/Confirm';

export const popupState = atom<PopupProps>({
  key: 'popupState',
  default: {
    isDimmed: true,
    title: '',
    width: 600,
    content: <></>,
    footer: undefined,
  },
});

export const confirmState = atom<ConfirmProps>({
  key: 'confirmState',
  default: {
    width: 280,
    message: '',
    leftText: '',
    rightText: '',
    leftFunc: () => {},
    rightFunc: () => {},
    show: false,
  },
});

export const loadingState = atom<{ show: boolean }>({
  key: 'loadingState',
  default: {
    show: false,
  },
});
