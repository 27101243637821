import React from 'react';

const usePopupToggle = (state, setState) => {
  const onTogglePopup = (key) => {
    const newObj = { ...state };
    newObj[key] = !newObj[key];
    setState(newObj);
  };
  return onTogglePopup;
};

export default usePopupToggle;
