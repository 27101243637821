import React, { useEffect, useState } from 'react';
import PopupTemplate from '../../../popup/PopupTemplate';
import { useRecoilState } from 'recoil';
import { popupState } from '../../../store/Atoms';
import { POPUP_KEYS } from '../../../service/popupKeys';
import Checkbox from '../../atoms/Checkbox';
import List from '../List';
import Button from '../../atoms/Button';
import Input from '../../atoms/Input';
import Select from '../../atoms/Select';
import ToggleButton from '../../atoms/ToggleButton';

const PopupComponent = ({ popState, setPopState }) => {
  const [popupData, setPopupData] = useRecoilState(popupState);
  const [isOpen, setIsOpen] = useState(false);

  //todo 차후 삭제(테스트용)
  const [option, setOption] = useState([
    { text: '옵션1', value: 1, active: true },
    { text: '옵션2', value: 2, active: false },
    { text: '옵션3', value: 3, active: false },
    { text: '옵션4', value: 4, active: false },
  ]);
  const [active, setActive] = useState(false);

  //todo 차후 삭제(테스트용)
  const onChange = (e) => {
    const value = e.target.ariaValueText;
    const newArr: any = [];
    option.map((opt) => {
      newArr.push({ text: opt.text, value: opt.value, active: value === String(opt.value) });
    });
    setOption(newArr);
    console.log(option);
  };

  const onClose = () => {
    setIsOpen(false);
    setPopState('');
  };

  useEffect(() => {
    if (!!popState) {
      setIsOpen(true);
    }
    switch (popState) {
      case POPUP_KEYS.CHANGE_PASSWORD:
        return setPopupData({
          width: 300,
          title: '비밀번호 변경',
          content: <List />,
          isDimmed: false,
          footer: <div>적용버튼</div>,
        });
      case POPUP_KEYS.LOT_INFO:
        return setPopupData({
          width: 300,
          title: '필지 정보',
          content: <div>필지 정보</div>,
          isDimmed: true,
        });
      case POPUP_KEYS.COMPONENTS:
        return setPopupData({
          width: 1200,
          title: '컴포넌트 모음',
          content: (
            <div style={{ padding: 10, display: 'flex' }}>
              <div style={{ flex: 1 }}>
                버튼
                <Button width={200} $buttonType={'small'} text={'작은버튼'} />
                <br />
                <Button width={200} $buttonType={'medium'} text={'중간버튼'} />
                <br />
                <Button width={200} $buttonType={'large'} text={'큰버튼'} />
                <br />
                <Button width={200} text={'큰버튼2'} />
              </div>
              <div style={{ flex: 1 }}>
                인풋
                <Input width={200} height={40} placeholder={'정상'} />
                <br />
                <Input width={200} height={40} placeholder={'비밀번호'} type={'password'} />
                <br />
                <Input width={200} height={40} error={true} placeholder={'에러'} />
                <br />
                <Input width={200} height={40} disabled={true} placeholder={'disabled'} />
              </div>
              <div style={{ flex: 1 }}>
                select
                <Select width={200} height={40} onChange={onChange} option={option} />
                <br />
                <Select width={200} height={40} onChange={onChange} option={option} disabled={true} />
                <br />
                checkbox
                <Checkbox text={'텍스트'} id={'test1'} />
                <Checkbox id={'test2'} type={'radio'} />
                <br />
                toggleBtn
                <ToggleButton
                  active={active}
                  onClick={() => {
                    setActive(!active);
                    console.log(active);
                  }}
                />
              </div>
            </div>
          ),
          isDimmed: true,
        });
    }
  }, [popState]);
  return <>{isOpen ? <PopupTemplate width={popupData.width} title={popupData.title} content={popupData.content} isDimmed={popupData.isDimmed} footer={popupData.footer} onClose={onClose} /> : null}</>;
};

export default PopupComponent;
