import React from 'react';
import styled from 'styled-components';

const LoadingStyle = {
  Dimmed: styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: rgba(0, 0, 0, 0.5);
  `,
  Wrap: styled.div`
    position: fixed;
    left: 50%;
    top: 50%;
    width: 420px;
    z-index: 1000;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.15);
  `,
  Inner: styled.div`
    padding: 25px 20px;
  `,
  PercentWrap: styled.div`
    position: relative;
    width: 100%;
    height: 10px;
    border-radius: 6px;
    background: #e8e8e8;
    overflow: hidden;
  `,
  Percent: styled.div`
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: #00b074;
    animation: progress 1s infinite linear;
    width: 0;
    border-radius: 8px;

    @keyframes progress {
      0% {
        width: 0;
      }
      100% {
        width: 100%;
      }
    }
  `,
};
const Loading = () => {
  return (
    <>
      <LoadingStyle.Dimmed></LoadingStyle.Dimmed>
      <LoadingStyle.Wrap>
        <LoadingStyle.Inner>
          <LoadingStyle.PercentWrap>
            <LoadingStyle.Percent></LoadingStyle.Percent>
          </LoadingStyle.PercentWrap>
        </LoadingStyle.Inner>
      </LoadingStyle.Wrap>
    </>
  );
};

export default Loading;
