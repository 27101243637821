import React from 'react';
import styled from 'styled-components';

export const ListStyle = {
  Wrap: styled.ul``,
  Item: styled.li`
    position: relative;
    display: flex;
    line-height: 20px;
    align-items: center;
    &:before {
      position: absolute;

      left: 0;
      bottom: 0;
      content: '';
      width: 96px;
      height: 1px;
      background: rgba(217, 217, 217, 0.62);
    }
    &:after {
      position: absolute;
      left: 100px;
      bottom: 0;
      content: '';
      width: calc(100% - 100px);
      height: 1px;
      background: rgba(217, 217, 217, 0.62);
    }
  `,
  Label: styled.strong`
    padding: 11px 0;
    min-width: 96px;
    color: #00b074;
    font-weight: 500;
    font-size: 14px;
    margin-right: 4px;
    height: 100%;
  `,
  Text: styled.p`
    flex: 1;
    padding: 11px 0;
    font-weight: 400;
    font-size: 14px;
    color: #3f3f3f;
    height: 100%;
  `,
};

const List = () => {
  return (
    <ListStyle.Wrap>
      <ListStyle.Item>
        <ListStyle.Label>테스트</ListStyle.Label>
        <ListStyle.Text>테스트</ListStyle.Text>
      </ListStyle.Item>
      <ListStyle.Item>
        <ListStyle.Label>테스트</ListStyle.Label>
        <ListStyle.Text>테스트테스트테스트테스트테스트테스트 테스트테스트테스트테스트테스트</ListStyle.Text>
      </ListStyle.Item>
    </ListStyle.Wrap>
  );
};

export default List;
